import Home from "./Home";
import { useEffect } from "react";
import { checkAuthCookie } from "../../utils/helpers";

const Dashboard = () => {
  useEffect(() => {
    checkAuthCookie();
  });
  return (
    <Home />
  );
};

export default Dashboard;
