import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GlobalLayout from "../../components/layout/GlobalLayout";
import School from "./School";

const schoolSteps = [
  {
    label: "School Details",
    content: School,
  }
];

export default function SetSchool() {

  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();

  const navigate = useNavigate();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <GlobalLayout>
      <Grid container style={{ marginTop: 100 }}>

    <Box sx={{ maxWidth: 800, margin: "auto", overflow: "scroll" }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ "& .css-8t49rw-MuiStepConnector-line": { minHeight: 0 } }}
      >
        {schoolSteps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel sx={{ padding: 0, textTransform: "uppercase" }}>
              <Box
                sx={{
                  mx: "1rem",
                  padding: "1rem",
                  backgroundColor:
                    activeStep === index
                      ? theme.palette.primary.main
                      : theme.palette.common.white,
                  borderRadius: index === 0 ? "10px 10px 0 0" : 0,
                  borderBottom:
                    activeStep !== index ? "1px solid #bdbdbd" : "none",
                }}
                //onClick={()=>setActiveStep(index)}
              >
                {step.label}
              </Box>
            </StepLabel>
            <StepContent sx={{ padding: 0 }}>
              <Box
                sx={{
                  marginRight: "1rem",
                  marginLeft: "2.2rem",
                  paddiing: "2rem 1rem",
                  py: "1rem",
                  backgroundColor: theme.palette.common.white,
                  borderBottom:
                    activeStep === index ? "1px solid #bdbdbd" : "none",
                }}
              >
                <Box sx={{ paddingLeft: "1rem" }}>
                  <step.content
                    steps={schoolSteps}
                    index={index}
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                </Box>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
    </Grid>
    </GlobalLayout>
  );
}
