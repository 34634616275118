import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.background.default,
}));

//const Logo = styled(Box)(({theme}))


const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const AuthTabContainer = styled(Box)(({ theme }) => ({
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "flex-start",
  // width: "50%",
  // // height: "100vh",  
  // maxWidth: "50vw",
  // position: "relative",
  // [theme.breakpoints.down("xs")]: {
  //   position: "absolute",
  //   top: '20vh',
  //   left: '14vh',
  //   bottom: "15vh",
  //   width: "50%",
  //   // "& .MuiButton-textSizeSmall": {size:"small"}

  // },

}));

const AuthLayout = ({ children }) => {
  return <Grid container >
    <Grid item xs={12}>
      <Box mt={{ xs: 3, lg: 9 }}><AuthTabContainer>{children}</AuthTabContainer></Box>
    </Grid>
  </Grid>
};

export default AuthLayout;
