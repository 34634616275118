import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Profile from "../../assets/images/sample_profile.jpeg";
import BookIcon from "../../assets/images/u_book-open.png";
import SettingIcon from "../../assets/images/u_bright.png";
import NoteIcon from "../../assets/images/u_clipboard-notes.png";
import HomeIcon from "../../assets/images/u_home-alt.png";
import QuestionIcon from "../../assets/images/u_question-circle.png";
import UploadIcon from "../../assets/images/u_upload.png";
import { useStore } from "../../stores";
import { drawerWidth } from "../../theme/customStyles";
import Spacer from "../elements/Spacer";

const SideMenu = () => {
  const theme = useTheme();
  const clearCurrentUser = useStore((state) => state.clearCurrentUser);
  const navigate = useNavigate();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: theme.palette.background.default,
          padding: "15px",
          border: "none",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: "100%",
          borderRadius: theme.spacing(2),
        }}
      >
        <Toolbar>
          <img src={Logo} alt="logo"/>
        </Toolbar>
        <Divider />
        <List>
          <ListItem>
            <Avatar src={Profile} variant="round" key="avatar"/>
          </ListItem>
          <Spacer height={2} />
          <ListItem disablePadding sx={{ height: 70 }} key="home">
            <ListItemButton
              onClick={() => {
                navigate("/schools");
              }}
            >
              <ListItemIcon
                style={{
                  marginTop: 0,
                  marginLeft: 5,
                }}
              >
                <img src={HomeIcon} alt="home"/>
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <>
            {[BookIcon, NoteIcon].map((icon, index) => (
              <ListItem disablePadding sx={{ height: 70 }} key={index}>
                <ListItemButton 
                  onClick={()=>{
                    navigate("/notebook")
                  }}
                >
                  <ListItemIcon
                    style={{
                      marginTop: 0,
                      marginLeft: 5,
                    }}
                  >
                    <img src={icon}  alt="icon"/>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
            <Spacer height={6} />
            {[SettingIcon, QuestionIcon].map((icon, index) => (
              <ListItem disablePadding sx={{ height: 70 }} key={index}>
                <ListItemButton>
                  <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
                    <img src={icon}  alt="icon"/>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem disablePadding sx={{ height: 70 }} key="logout">
              <ListItemButton onClick={clearCurrentUser}>
                <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
                  <img src={UploadIcon} alt="logout"/>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </>
        </List>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
