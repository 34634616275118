import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/system";
import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import FormError from "./FormError";
import { useLocation } from "react-router-dom";

const Input = ({
  value,
  onChange = (e) => { },
  width = "100%",
  label = "",
  maxChar,
  errorText = "",
  defaultValue = "",
  select = false,
  shrink = false,
  options = [],
  obscureText = false,
  ml,
  minChar,
  ...props
}) => {
  const theme = useTheme();
  const [char, setChar] = useState(0);
  const { pathname } = useLocation();

  const onInputChange = (e) => {
    if (e.target.value.length <= maxChar && e.target.value.length>=minChar) {
      setChar(e.target.value.length);
    } else if (e.target.value.length > maxChar) {
      e.target.value = e.target.value?.slice(0, maxChar);
      setChar(maxChar);
    }
    
    
    onChange(e);
  };

  useEffect(() => {
    setChar(0);
  }, [pathname]);

  return (
    <FormControl sx={{ width: width }} variant="standard">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          " .MuiFormLabel-root-MuiInputLabel-root": {
            top: "auto",
            bottom: "1.37rem",
            fontSize: "12px",
          },
        }}
      >
        <TextField
          type={obscureText ? "password" : "text"}
          label={label}
          value={value}
          helperText={defaultValue}
          select={select}
          InputLabelProps={
            shrink
              ? {
                shrink: shrink,
              }
              : {}
          }
          fullWidth
          sx={{
            outline: "none",
            padding: 0,
            "& input": {
              marginLeft: ml ? ml : "0px",
              padding: "15px",
            },
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.secondary.light,
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '12px',
                borderColor: theme.palette.secondary.main,
                // '& legend': {
                //   fontSize: 10
                // }
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
              },
            },
            position: "relative",
          }}
          onChange={onInputChange}
          {...props}
        >
          {options?.length
            ? options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
            : null}
        </TextField>
        <FormHelperText
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "10px"
          }}
        >
          <FormError error={errorText} />
          <span>{maxChar ? `${char}/${maxChar}` : ""}</span>
        
        </FormHelperText>
      </Box>
    </FormControl>
  );
};

export default Input;
