import { Card, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GlobalLayout from '../../components/layout/GlobalLayout';

const Home = () => {
  const navigate = useNavigate();

  const cardArr = [
    { route: "/students", label: "Students" },
    // { route: "/admins", label: "Admins" },
    // { route: "/roles", label: "Roles" },
    { route: "/schools", label: "Schools" },
  ]
  return (   
    <GlobalLayout>
      <Grid container justifyContent='center' alignItems='center' style={{marginTop: 100, height: "calc(100vh - 128px)" }}>
        <Grid container item xs={8} spacing={2} justifyContent='center' alignItems='center'>
          {
            cardArr.map((option) => {
              return <Grid item xs={6} key={option.label}>
                <Card onClick={() => navigate(option.route)} sx={{ padding: '100px 0', textAlign: 'center', cursor: "pointer" }}>
                  <Typography variant='h4'>{option.label}</Typography>
                </Card>
              </Grid>
            })
          }
        </Grid>
      </Grid> 
    </GlobalLayout>
  );
};

export default Home;
