import { Box, Toolbar } from "@mui/material";
import React from "react";
import GlobalLayout from "../../components/layout/GlobalLayout";
import SetProfile from "./SetProfile";

const SetUserProfile = () => {
  return (
    <Box>
      <GlobalLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 4,
            // px: 1,
            marginRight: "230px",
            minHeight: "100vh",
          }}
        >
          <Toolbar />
          <SetProfile />
        </Box>
      </GlobalLayout>
    </Box>
  );
};

export default SetUserProfile;
