import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import { Button } from '@material-ui/core';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useField } from "../../hooks/useField";
import {
  isEmailValid,
  isPasswordValid,
  isRequired,
} from "../../utils/validators";
import OtpView from "./OtpView";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const SignupWithEmailPass = () => {
  const navigate = useNavigate();
  const { register } = useAuthHelper();

  const email = useField({ validate: isEmailValid });
  const password = useField({
    validate: isPasswordValid,
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const confirmPassword = useField({ validate: isRequired });
  const [passwordNoMatchError, setPasswordNoMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setPasswordNoMatchError("");
  }, [password.value, confirmPassword.value]);

  const formErrCleanupValidation = () => {
    email.setShowError(true);
    password.setShowError(true);
    setPasswordNoMatchError("");

    if (!email.isValid || !password.isValid) return false;
    if (password.value !== confirmPassword.value) {
      setPasswordNoMatchError("Passwords do not match");
      return false;
    }
    return true;
  };

  const onFormSubmit = async () => {
    if (formErrCleanupValidation()) {
      setOtpSent(true);
      let res = await register({
        email: email.value,
        password: password.value,
      });
      if (!res.data?.success) {
        setOtpSent(false);
      }
    }
  };

  function handleEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onFormSubmit()
    }
  }


  if (!otpSent)
    return (
      <FlexContainer direction="column">
        {/* <ArrowBackIcon sx={backIconStyles()} onClick={() => navigate(-1)} /> */}
        <Box sx={{ color: 'rgba(0, 0, 0, 0.6)', width: "100%", position: "relative" }}>
          <Input
            {...email.getInputProps()}
            errorText={email.showError && email.meta.error}
            label="E-mail Address"
            style={{ marginBottom: "32px" }}
            InputLabelProps={{
              style: {
                // fontSize: "12px", width: "87px",


                // height: "13px",
                //   flexGrow: "0",
                //   fontWeight: "500",
                //   fonStretch: "normal",
                //   fontStyle: "normal",
                //   lineHeight: "normal",
                //   letterSpacing: "0.24px",
                //   textAlign: "left",

                // marginLeft: '5px',
                flexGrow: "0",
                fontWeight: "500",
                fonStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.24px",
                textAlign: "left",
                margin: 0
              }
            }}
          />
        </Box>
        {/* <Spacer sx={{ height: { xs: "100vh", md: "50vh" } }} /> */}
        <FlexContainer al="flex-start" wrap={matches}>
          <Input
            {...password.getInputProps()}
            errorText={password.showError && password.meta.error}
            label="Password"
            obscureText={!showPassword}
            style={{ margin: "0px 8px 5px 0" }}
            InputLabelProps={{
              style: {
                // fontSize: "12px", width: "87px",
                // height: "13px",
                // flexGrow: "0",
                // fontWeight: "500",
                // fonStretch: "normal",
                // fontStyle: "normal",
                // lineHeight: "normal",
                // letterSpacing: "0.24px",
                // textAlign: "left",
                // marginTop: { xs: "80px" }
                flexGrow: "0",
                fontWeight: "500",
                fonStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.24px",
                textAlign: "left",
              }
            }}

            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {/* <Spacer sx={{ width: { xs: "90px", md: "5vh" } }} /> */}
          <Input
            {...confirmPassword.getInputProps()}
            errorText={password.showError && confirmPassword.meta.error}
            label="Confirm"
            onKeyPress={handleEnter}
            style={{ margin: "0px 0 5px 8px" }}
            InputLabelProps={{
              style: {
                // fontSize: "12px", width: "87px",
                // height: "13px",
                // flexGrow: "0",
                // fontWeight: "500",
                // fonStretch: "normal",
                // fontStyle: "normal",
                // lineHeight: "normal",
                // letterSpacing: "0.24px",
                // textAlign: "left",
                // width: "calc(100% - 8px)"


                flexGrow: "0",
                fontWeight: "500",
                fonStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.24px",
                textAlign: "left",
                width: "calc(100% - 8px)"

              }
            }}
            obscureText={!showPassword}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FlexContainer>
        {
          passwordNoMatchError &&
          <Typography
            color={"error"}
            fontSize=".8rem"
            sx={{ alignSelf: "flex-start", marginTop: "10px" }}
          >
            {passwordNoMatchError ? <InfoIcon fontSize="small" /> : null}
            {passwordNoMatchError}
          </Typography>
        }
        <Typography
          variant="caption"
          padding="1px"
          fontWeight='500'
          color='rgba(0, 0, 0, 0.6)'
          sx={{ fontSize: '12px', letterSpacing: '0.24px', marginBottom: '24px', marginLeft: "15px" }}
        >
          Use 8 or more characters with a mix of small & capital letters,
          numbers & special charcter
        </Typography>

        {/* <Spacer /> */}
        {/* <ButtonCustom onClick={onFormSubmit} margin="0"> */}
        {/* <Box sx={{ whiteSpace: "nowrap" }}>Sign Up</Box> */}
        <Button onClick={onFormSubmit} variant="contained" component="span" style={{ borderRadius: '50px', fontSize: '14px', backgroundColor: '#82D2DA', boxShadow: 5, fontWeight: 550, margin: 'auto', outline: 'none' }}  >Sign Up</Button>
        {/* </ButtonCustom> */}

        {/* <Spacer /> */}

        <Typography
          onClick={() => {
            navigate("/signup/phone");
          }}
          sx={{
            cursor: "pointer",
            margin: '24px 0 16px',
            flexGrow: 0,
            opacity: 0.9,
            fontFamily: 'GraphikMedium.oft',
            fontSize: '12px',
            fontWeight: 550,
            fontStyle: 'bold',
            lineHeight: 'normal',
            letterSpacing: '0.18px',
            textAlign: 'center',
          }}
          color="#005B64"
          align="center"
        >
          Sign-Up Using Mobile Number
        </Typography>
      </FlexContainer>

    );
  if (otpSent) return <OtpView email={email} isRegister={false} />;
};

export default SignupWithEmailPass;
