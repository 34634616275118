import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminRole from "./AdminRole";
import Education from "./Education";
import Payment from "./Payment";
import PersonalInfo from "./PersonalInfo";

const studentSteps = [
	{
		label: "Personal Information",
		content: PersonalInfo,
	},
	{
		label: "Education",
		content: Education,
	},
	{
		label: "Payments",
		content: Payment,
	},
];
const adminSteps = [
	{
		label: "Personal Information",
		content: PersonalInfo,
	},
	{
		label: "Admin Roles",
		content: AdminRole,
	},
];

export default function SetProfile() {
	let { paramsUserId } = useParams();
	const profileSteps = paramsUserId.includes("Student")
		? studentSteps
		: adminSteps;
	const [studentId, setStudentId] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [role, setRole] = useState();
	const [pincode, setPincode] = useState();
	const theme = useTheme();

	const navigate = useNavigate();
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	useEffect(() => {
		if (activeStep === profileSteps.length) {
			navigate("/schools");
		}
	}, [activeStep]);

	return (
		<Box sx={{ maxWidth: 800, margin: "auto", overflow: "scroll" }}>
			<Stepper
				activeStep={activeStep}
				orientation="vertical"
				sx={{ "& .css-8t49rw-MuiStepConnector-line": { minHeight: 0 } }}
			>
				{profileSteps.map((step, index) => (
					<Step key={step.label}>
						<StepLabel sx={{ padding: 0, textTransform: "uppercase" }}>
							<Box
								sx={{
									mx: "1rem",
									padding: "1rem",
									backgroundColor:
										activeStep === index
											? theme.palette.primary.main
											: theme.palette.common.white,
									borderRadius: index === 0 ? "10px 10px 0 0" : 0,
									borderBottom:
										activeStep !== index ? "1px solid #bdbdbd" : "none",
								}}
								//onClick={()=>setActiveStep(index)}
							>
								{step.label}
							</Box>
						</StepLabel>
						<StepContent sx={{ padding: 0 }}>
							<Box
								sx={{
									marginRight: "1rem",
									marginLeft: "2.2rem",
									paddiing: "2rem 1rem",
									py: "1rem",
									backgroundColor: theme.palette.common.white,
									borderBottom:
										activeStep === index ? "1px solid #bdbdbd" : "none",
								}}
							>
								<Box sx={{ paddingLeft: "1rem" }}>
									<step.content
										steps={profileSteps}
										index={index}
										handleNext={handleNext}
										handleBack={handleBack}
										setStudentId={setStudentId}
										studentId={studentId}
										role={role}
										setRole={setRole}
										pincode={pincode}
										setPincode={setPincode}
									/>
								</Box>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}
