import { Endpoints } from "../constants/endpoints"
import { environment } from "../environments/environment"
import axiosInstance from "../services/api.service";

const useSessionHelper = () => {
    const sync = async (grade,schoolId) => {
        let url = environment.sessionUrl + `sessions/sync?grade=${grade}&school=${schoolId}`;
        return await axiosInstance(false).post(url)
    }
    const getSync = async (schoolId) => {
        let url = environment.sessionUrl + `sessions/sync?school=${schoolId}`;

        return await axiosInstance(false).get(url)
    }

    const getReports = async ({schoolId,grade,subject, meta}) => {
        let url = environment.sessionUrl + `sessions/reports?school=${schoolId}`;
        if(grade){
            url+=`&grade=${grade}`
        }
        if(subject){
            url+=`&subject=${subject}`
        }
        if(meta==false){
            url+=`&meta=false`
        }
        return await axiosInstance(false).get(url)
    }

    const preProcess = async ({schoolId}) => {
        let url = environment.contentUrl + `/tests/preProcess?schoolId=${schoolId}`;
        return await axiosInstance(false).post(url)
    } 

    const getPackage = async (packageId) => {
        let url = environment.contentUrl + `/packages/${packageId}`;
        return await axiosInstance(false).get(url)
    }

    const getSessionImages = async (sessionId) => {
        let url = environment.sessionUrl + `/sessions/images/${sessionId}`;
        return await axiosInstance(false).get(url)
    }

    const saveMedal = async (data) => {
        let url = environment.sessionUrl + `/sessions/medal`;
        return await axiosInstance(false).post(url,data)
    }

    const getAwards = async () => {
        let url = environment.sessionUrl + `/sessions/schoolAwards`;
        return await axiosInstance(false).get(url)
    }
    const postWeight = async(data)=>{
        let url = environment.sessionUrl + `/sessions/schoolWeights`;
        return await axiosInstance(false).post(url,data)
    }
    const getMedals = async()=>{
        let url = environment.sessionUrl + `/sessions/genGoldWinners?topPercent=6`;
        return await axiosInstance(false).get(url)
    }

    return {
        sync,
        getSync,
        getReports,
        preProcess,
        getPackage,
        getSessionImages,
        saveMedal,
        getAwards,
        postWeight,
        getMedals,
    }
}

export default useSessionHelper; 