import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { Link, Box, Modal, CircularProgress, Backdrop } from "@mui/material";
import { Button, Grid } from "@mui/material";
import GlobalLayout from "../../components/layout/GlobalLayout";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import useSchoolHelper from "../../hooks/useSchoolHelper";
import { CustomSnackbar } from "../../components/elements/CustomSnackbar";
import AlertMessageModal from "../../components/attention/AlertMessageModal";
import { useNavigate, useParams } from "react-router-dom";
import InviteUser from "../profile/InviteUser";
import BulkUser from "../profile/BulkUser";
import { studentInfoObj } from "../../hooks/useFeatureHelper";
import ResetPassword from "../profile/ResetPassword";
import { Stack } from "@mui/system";
import ResetPasswordModal from "../profile/ResetPassword";
import { CsvBuilder } from "filefy";
import useUsersHelper from "../../hooks/useAdminHelper";

const Coordinators = () => {
  const navigate = useNavigate();

  const [currentRowId, setCurrentRowId] = React.useState(null);
  const [loading,setLoading] = useState(false);
  const [uploadFileUrl] = React.useState("");
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [importFileFlag, setImportFileFlag] = useState(false);
  const [delMessage, setDelMessage] = React.useState(false);

  const [tableArr, setTableArr] = React.useState([]);
  const [countPerPage, setCountPerPage] = useState(10);
  const [tableTotalCount, setTableTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [modalLoaderFlag, setModalLoaderFlag] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const {deleteUser,getUsers ,inviteOneUser} = useUsersHelper();
  const query = new URLSearchParams(window.location.search);
  const schoolId = query.get("schoolId");
  const { data: usersData, isLoading: userLoader } = useQuery(
    [`UsersData`, importFileFlag],
    () => getUsers({schoolId:schoolId,role:"SCHOOL_ADMIN"}),
    { enabled: true, retry: false }
  );
  useEffect(() => {
    if (usersData) {
      console.log("usersData?.data?",usersData?.data?.data?.students?.data)
      setTableArr(usersData?.data?.data?.students?.data);
      setTableTotalCount(usersData?.data?.data?.students?.data?.length);
    }
  }, [usersData, tableArr]);
  useEffect(() => {
    getUsers(schoolId).then((res) => {
      console.log(res);
      setTableArr(res?.data?.data?.data);
      setTableTotalCount(res?.data?.data?.totalElements);
    });
  }, [schoolId]);
  const deleteRow = () => {
    deleteMutate(currentRowId);
  };
  const { mutate: deleteMutate } = useMutation(deleteUser, {
    onSuccess: (data, variables, context) =>
      onSuccessUserDelete(data, variables, context),
    onError: (data, variables, context) =>
      onErrorUserDelete(data, variables, context),
  });


  const onSuccessUserDelete = (data, variables, context) => {
    if (data) {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: "success",
      });
      setImportFileFlag(true);
      setDelMessage(false);
    }
  };

  const onErrorUserDelete = (error, variables, context) => {
    error &&
      setSnakeBarProps({
        snackbarFlag: true,
        msz: error.message,
        type: "error",
      });
  };
  var userInfoVal = window.localStorage.getItem("current_user");
  var userInfo = JSON.parse(userInfoVal).state.currentUser;
  var userRoles=userInfo.userRole;
  console.log("userInfo.id",userInfo.id)
  var userIdLocal=userInfo.id
  return (
    <GlobalLayout loader={userLoader}>
      <Grid container m={2} pr={3} style={{ marginTop: 100 }}>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center">
          <Button onClick={() =>{if(userRoles!=="SCHOOL_ADMIN") {navigate('/CoordinatorForm?schoolId='+schoolId)}}} style={{ borderRadius: '50px', marginRight: '20px',color:userRoles!=="SCHOOL_ADMIN" ? "" : "grey",textDecoration:"none"   }} variant='contained'> New Coordinator</Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          { }
          <MaterialTable
            columns={[
              
              { title: 'Name', field: 'fullName' },
              { title: 'Email', field: 'email'},
              { title: 'Password', field: 'securityPin'},
              { title: 'Phone Number', field: 'phoneNumber' },
             
              {
                title: 'Actions ', field: 'status',
                render: rowData => {
                  return (
                    <Grid container>
                      <Grid item>
                        <Button>
                          <Link style={{ marginRight: '20px' }} href='#' onClick={()=>inviteOneUser({phoneNumber:rowData.phoneNumber})} >Invite</Link>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button>
                          <Link style={{ marginRight: '20px',color:userRoles!=="SCHOOL_ADMIN" || (userRoles==="SCHOOL_ADMIN" && rowData.id==userIdLocal)? "" : "grey" }} href='#' onClick={() => {
                            if(userRoles!=="SCHOOL_ADMIN" || (userRoles==="SCHOOL_ADMIN" && rowData.id==userIdLocal)){
                            setResetPassword(true);
                            setCurrentRowId(rowData.id);}}
                          }>Reset Password</Link>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button>
                          <Link style={{ marginRight: '20px',color:userRoles!=="SCHOOL_ADMIN" || (userRoles==="SCHOOL_ADMIN" && rowData.id==userIdLocal)? "" : "grey",textDecoration:"none"  }} onClick={()=>{ if(userRoles!=="SCHOOL_ADMIN" || (userRoles==="SCHOOL_ADMIN" && rowData.id==userIdLocal)) navigate('/CoordinatorForm?schoolId='+schoolId + "/" + rowData.id)} }>Edit</Link>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button>
                          <Link style={{ marginRight: '20px',color:userRoles!=="SCHOOL_ADMIN" || (userRoles==="SCHOOL_ADMIN" && rowData.id==userIdLocal)? "" : "grey",textDecoration:"none" }} href='#' onClick={() => {
                           if(userRoles!=="SCHOOL_ADMIN" || (userRoles==="SCHOOL_ADMIN" && rowData.id==userIdLocal))
                          {  setDelMessage(true);
                            setCurrentRowId(rowData.id);
                          }
                          }} >Delete</Link>
                        </Button>
                      </Grid>
                   </Grid>


                  )
                }
              }
            ]}

            totalCount={tableTotalCount}
            data={tableArr}
            title="Coordinators"
            options={{
              pageSize: 100,
              headerStyle: {
                backgroundColor: '#82D2DA'
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
          {delMessage && (
            <AlertMessageModal
              setDelMessage={setDelMessage}
              alertMessage="Do you want to remove the user?"
              okbtnFlag="true"
              cancelBtnFlag="true"
              onClose={() => {
                setDelMessage(false);
              }}
              onClick={() => {
                deleteRow();
              }}
            />
          )}
          {
            <ResetPasswordModal
              userId={currentRowId}
              open={resetPassword}
              setOpen={setResetPassword}
            />
          }
        </Grid>
      
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </GlobalLayout>
  );
};

export default Coordinators;
