import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

const useRoleHelper = () => {
    const getRoles = async () => {
        const endpoint =environment.userBaseUrl+Endpoints.ROLE;
        return await axiosInstance().get(endpoint);
    }
    const getRole = async (roleId) => {
        if(roleId !== "create"){
            const endpoint =environment.userBaseUrl+Endpoints.ROLE+`/${roleId}`;
            return await axiosInstance().get(endpoint);
        }

    }
    const saveRole = async (body,roleId) => {
        if(roleId === "create"){
            const endpoint = environment.userBaseUrl+Endpoints.ROLE;
            return await axiosInstance().post(endpoint,body);
        } else{
            const endpoint = environment.userBaseUrl+ Endpoints.ROLE+`/${roleId}`;
            return await axiosInstance().put(endpoint,body);
        }
    }

    const deleteRole = async (roleId) => {
        const endpoint = environment.userBaseUrl+ Endpoints.ROLE+`/${roleId}`;
        return await axiosInstance().delete(endpoint);
    }
    return {
        getRoles,
        saveRole,
        deleteRole,
        getRole
    }
}

export default useRoleHelper;