/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useField } from "../../hooks/useField";
import { isPhoneNumberValid } from "../../utils/validators";
import OtpView from "./OtpView";
import { Button } from '@material-ui/core';

const WithPhone = ({ action }) => {
  const isRegister = action === "SIGNUP";
  const navigate = useNavigate();
  const { generateOTP } = useAuthHelper();

  const [otpSent, setOtpSent] = useState(false);

  const phoneNumber = useField({
    validate: isPhoneNumberValid,
    inputType: "number",
  });

  useEffect(() => {
    phoneNumber.setShowError(false);
  }, [phoneNumber.value, phoneNumber.isFocused]);

  useEffect(() => {
    phoneNumber.setValue("");
    setOtpSent(false);
  }, [isRegister]);

  const onPhoneNumberSubmit = async () => {
    // setOtpSent(true);
    phoneNumber.setShowError(true);
    if (!phoneNumber?.isValid) return;
    setOtpSent(true);
    let res = await generateOTP({
      phoneNumber: "+91" + phoneNumber?.value,
      signUp: isRegister,
    });
    if (!res.data?.success) {
      setOtpSent(false);
    }
  };

  function handleEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onPhoneNumberSubmit()
    }
  }


  if (!otpSent)
    return (
      <FlexContainer direction="column">
        <Box sx={{ color: 'rgba(0, 0, 0, 0.6)', width: "100%", position: "relative" }}>
          <Input
            InputLabelProps={{
              style: {
                flexGrow: "0",
                fontWeight: "500",
                fonStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "0.24px",
                textAlign: "left",
                backgroundColor: "#fff"
              }
            }}
            {...phoneNumber.getInputProps()}
            label="Your 10-digit mobile no."
            maxChar={10}
            ml="40px"
            errorText={phoneNumber.showError && phoneNumber?.meta?.error}
            onKeyPress={handleEnter}
          />
          <Box
            sx={{
              position: "absolute",
              top: "13.9px",
              left: "10px",
              color: "#000",
              visibility:
                phoneNumber.isFocused || phoneNumber.value.length
                  ? "visible"
                  : "hidden",
            }}
          >
            +91 -
          </Box>
        </Box>
        {/* <Spacer height={2} /> */}
        <Grid marginTop="24px">
          <Button onClick={onPhoneNumberSubmit} variant="contained" component="span" style={{ borderRadius: '50px', fontSize: '14px', backgroundColor: '#82D2DA', boxShadow: 5, fontWeight: 550, margin: 'auto', outline: 'none' }}  >
            Generate Otp
          </Button>
        </Grid>
        {/* <Spacer /> */}
        <Typography
          align="center"
          sx={{
            cursor: "pointer",
            opacity: 0.9,
            fontFamily: 'GraphikMedium.oft',
            fontSize: '12px',
            fontWeight: 550,
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.18px',
            textAlign: 'center',
            margin: "24px 0 16px"
          }}
          onClick={() => {
            navigate(
              action === "SIGNUP" ? "/signup/emailpass" : "/login/emailpass"
            );
          }}
          color="#005B64"
        >
          {isRegister ? "Sign-Up" : "Login"} Using Email Address
        </Typography>
      </FlexContainer>
    );
  if (otpSent)
    return <OtpView phoneNumber={phoneNumber} isRegister={isRegister} />;
};

export default WithPhone;
