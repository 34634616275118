import { isEmail, isNumeric } from "validator";

export const isRequired = ({ value }) => (!value?.trim() ? "Required" : null);

export const isEmailValid = ({ value }) =>
  !value?.trim()
    ? "Required"
    : isEmail(value?.trim())
    ? null
    : "Please enter a valid email";

export const isPasswordValid = ({ value }) =>
  !value?.trim()
    ? "Required"
    : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        value?.trim()
      )
    ? null
    : "Not valid pattern";

export const isPhoneNumberValid = ({ value }) =>
  !value?.trim()
    ? "Required"
    : isNumeric(value?.trim()) && value?.trim().length === 10
    ? null
    : /^\+91[\d]{10}/.test(value?.trim()) ? null : "Please Enter a 10-digit mobile no.";

export const isValidEmailOrMob = ({ value }) =>
  !value?.trim()
    ? "Required"
    : isEmail(value?.trim()) ||
      (isNumeric(value?.trim()) && value?.trim().length === 10)
    ? null
    : "Please Enter a valid email or 10-digit mobile no.";

export const isPincodeValid = ({ value }) =>
  !value
    ? "Required"
    : isNumeric(String(value)) && String(value).length === 6
    ? null
    : "Please enter a valid 6 digit pincode";
