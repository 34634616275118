import { Box, Button } from "@mui/material";
import React from "react";
import ButtonCustom from "../../components/elements/ButtonCustom";
import FlexContainer from "../../components/elements/FlexContainer";
import Spacer from "../../components/elements/Spacer";

const ProfileFormFooter = ({
  steps,
  index,
  handleNext,
  handleSave,
  handleBack,
  nextdisabled = false,
}) => {
  return (
    <FlexContainer>
      {index === 0 ? <Spacer width="100px" /> : null}
      <FlexContainer>
      <Box width="100px">
          <ButtonCustom onClick={handleSave}>
            Save
          </ButtonCustom>
        </Box>
        <Box width="100px">
          <ButtonCustom onClick={handleNext} disabled={nextdisabled}>
            {index === steps.length - 1 ? "Finish" : "Next"}
          </ButtonCustom>
        </Box>
        {index !== 0 && <Button
          variant="outlined"
          disabled={index === 0}
          color="secondary"
          sx={{
            borderRadius: "50px",
            "&:hover": { outline: "none" },
          }}
          onClick={handleBack}
        >
          Back
        </Button> }
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProfileFormFooter;
