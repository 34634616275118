import { Box, CssBaseline } from "@mui/material";
import React from "react";
import CustomLoader from "../CustomLoader";
import AppbarTop from "./AppbarTop";
import SideMenu from "./SideMenu";

const GlobalLayout = ({ children, loader }) => {
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", position: "relative" }}>

          <CssBaseline />
         
          <SideMenu />
          {
            loader ? <CustomLoader />
              :
              <>{children}</>
          }
        </Box>
      </Box>
    </>
  );
};

export default GlobalLayout;
