import Modal from "@mui/material/Modal";

export default function CustomModal({
  open = true,
  setOpen,
  children,
  closeOnOutsideClick = true,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (
            reason !== "backdropClick" ||
            (closeOnOutsideClick && reason === "backdropClick")
          ) {
            setOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "scroll",
        }}
      >
        {children}
      </Modal>
    </div>
  );
}
