import { Card } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ForgotPass from "./ForgotPass";
import LoginWithEmailPass from "./LoginWithEmailPass";
import SignupWithEmailPass from "./SignupWithEmailPass";
import WithPhone from "./WithPhone";
import FlexContainer from "../../components/elements/FlexContainer";

const StyledTab = styled(Tab)(({ theme }) => ({
  backgroundColor: "#82d2da !important",
  color: `${theme.palette.secondary.main} !important`,
  "&:active": {
    outline: "none",
  },
  "&.Mui-selected": {
    outline: "none",
    backgroundColor: `${theme.palette.primary.dark} !important`,
    color: `${theme.palette.secondary.black} !important`,

  },
  fontWeight: 650,
}));
export default function AuthTabs() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { routeType } = useParams();

  const [value, setValue] = useState(pathname.includes("signup") ? 0 : 1);

  const handleChange = (newValue) => {
    if (newValue === 0) {
      if (pathname.includes("emailpass")) {
        navigate("/signup/emailpass");
      } else navigate("/signup/phone");
    } else if (newValue === 1) {
      if (pathname.includes("emailpass")) {
        navigate("/login/emailpass");
      } else navigate("/login/phone");
    }
    setValue(newValue);
  };

  useEffect(() => {
    setValue(pathname.includes("signup") ? 0 : 1);
  }, [pathname]);

  return (
    <FlexContainer direction="column" jc="flex-start" padding="20px">
      <Card
        sx={{
          display: "flex",
          px: { xs: 2, md: 6 },
          py: '48px',
          blur: "8px",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.12)",
          width: { xs: "90vw", sm: "40vw", md: "35vw" },
          position: "relative",
        }}
      >
        {value === 0
          ? (routeType === "phone" && <WithPhone action="SIGNUP" />) ||
          (routeType === "emailpass" && <SignupWithEmailPass />)
          : (routeType === "phone" && <WithPhone action="LOGIN" />) ||
          (routeType === "emailpass" && <LoginWithEmailPass />)}
        {pathname.includes("forgotpass") && <ForgotPass />}
      </Card>
    </FlexContainer>
  );
}
