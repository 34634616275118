import { Box, Card, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useOnboardingHelper from "../../hooks/useOnboardingHelper";
import { useField } from "../../hooks/useField";
import { isRequired } from "../../utils/validators";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import ProfileFormFooter from "./ProfileFormFooter";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { ProfileWrapper } from "./ProfileWrapper";
import ButtonCustom from "../../components/elements/ButtonCustom";
import CustomModal from "../../components/elements/CustomModal";
import useSchoolHelper from "../../hooks/useSchoolHelper";
import useUsersHelper from "../../hooks/useAdminHelper";
import useStudentHelper from "../../hooks/useStudentHelper";

const VerticalSpacer = () => <Spacer height={2} />;
const Education = ({ steps, index, handleBack, handleNext, studentId }) => {
	const query = new URLSearchParams(window.location.search);
	const schoolId = query.get("school");
	const school = useField({ validate: isRequired });
	const section = useField({ validate: isRequired });
	const grade = useField({ validate: isRequired });
	const { getGrades } = useUsersHelper();
	const { data: schoolData } = useQuery(
		["School", schoolId],
		() => getSchoolInfo(schoolId),
		{ enabled: !!schoolId }
	);

	const { data: grades } = useQuery(
		["Grades"],
		() => getGrades(schoolData?.data?.data?.type),
		{ enabled: !!schoolData?.data?.data?.type }
	);
	console.log(grades?.data?.data);
	const { getAllSchools, getSchoolInfo } = useSchoolHelper();
	const { getStudent, saveStudent } = useStudentHelper();
	const [schoolList, setSchoolList] = useState([]);

	useEffect(() => {
		getAllSchools().then((res) => {
			console.log(res?.data?.data);
			setSchoolList(res?.data?.data);
		});
	}, []);

	const { data: StudentData, refetch } = useQuery(
		["student", studentId],
		() => getStudent(studentId),
		{ enabled: !!studentId, refetchOnWindowFocus: false }
	);

	useEffect(() => {
		if (StudentData && schoolData) {
			section.setValue(StudentData?.data?.data?.section);
			grade.setValue(StudentData?.data?.data?.grade);
			school.setValue(schoolData?.data?.data?._id);
		}
	}, [schoolData, StudentData]);

	const formErrCleanupValidation = () => {
		school.setShowError(true);
		section.setShowError(true);
		grade.setShowError(true);
		if (
			!school.isValid ||
			!section.isValid ||
			!grade.isValid
			// !extraCurricular.isValid ||
			// !studyField.isValid ||
			// !description.isValid ||
			// !attachment.isValid
		) {
			return false;
		}
		return true;
	};

	const onSubmit = async () => {
		if (formErrCleanupValidation()) {
			submitEducationInfo();
		}
	};

	const submitEducationInfo = async () => {
		let res = await saveStudent({
			...StudentData?.data?.data,
			schoolId: school.value,
			grade: grade.value,
			section: section.value,
		});
		if (res.data.success) {
			handleNext();
		}
	};

	return (
		<>
			<Box>
				<Box margin="auto" width="600px">
					<Input
						label="School/ College/ University*"
						select
						{...school.getInputProps()}
						options={schoolList?.map((school) => ({
							value: school._id,
							label: school.name,
						}))}
						errorText={school.showError && school.meta.error}
						width="593px"
					/>
					<span style={{ color: "red", fontWeight: "bold" }}>*</span>
					<VerticalSpacer />
					<Input
						label="Grade"
						select
						options={grades?.data?.data?.map((grade) => ({
							value: grade.groupName,
							label: grade.groupName,
						}))}
						{...grade.getInputProps()}
						errorText={grade.showError && grade.meta.error}
						width="593px"
					/>
					<span style={{ color: "red", fontWeight: "bold" }}>*</span>
					<VerticalSpacer />
					<Input
						label="Section"
						maxChar={100}
						{...section.getInputProps()}
						errorText={section.showError && section.meta.error}
						width="593px"
					/>
					<span style={{ color: "red", fontWeight: "bold" }}>*</span>
				</Box>
			</Box>
			<Box my={3}>
				<ProfileFormFooter
					steps={steps}
					index={index}
					handleSave={onSubmit}
					handleNext={handleNext}
					handleBack={handleBack}
				/>
			</Box>
		</>
	);
};

export default Education;
