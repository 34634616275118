import { Box } from "@mui/material";
import { styled } from "@mui/system";

const FlexWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
}));

const FlexContainer = ({
  children,
  direction,
  width,
  height,
  wrap,
  jc,
  al,
  padding,
}) => {
  return (
    <FlexWrapper
      sx={{
        width: width ? width : "100%",
        height: height ? height : "auto",
        padding: padding ? padding  : "0",
        flexDirection: direction ? direction : "row",
        justifyContent: jc ? jc : "center",
        alignItems: al ? al : "center",
        flexWrap: wrap ? "wrap" : "nowrap",

      }}
    >
      {children}
    </FlexWrapper>
  );
};

export default FlexContainer;
