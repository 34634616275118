import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import ButtonCustom from "../../components/elements/ButtonCustom";
import FlexContainer from "../../components/elements/FlexContainer";
import FormError from "../../components/elements/FormError";
import Spacer from "../../components/elements/Spacer";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useStore } from "../../stores";
import { backIconStyles } from "../../theme/customStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useOnboardingHelper from "../../hooks/useOnboardingHelper";

const OtpView = ({
  phoneNumber,
  email,
  isRegister,
  forAuth = true,
  revertChange = () => { },
  onSuccess = () => { },
}) => {
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const { validateOTP, generateOTP } = useAuthHelper();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState();
  const time = new Date();
  const { seconds, minutes, isRunning, restart } = useTimer({
    autoStart: true,
    expiryTimestamp: time.setSeconds(time.getSeconds() + 60),
    onExpire: () => console.warn("onExpire called"),
  });

  const { validateOtpForModifyEmailOrPhone } = useOnboardingHelper();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const onOtpSubmit = async () => {
    if (otp.length < 6) {
      setError("Please enter a 6 digit OTP");
      return;
    }
    let validateOtpReqBody = { otp: +otp };

    validateOtpReqBody = email
      ? { ...validateOtpReqBody, email: email.value }
      : {
        ...validateOtpReqBody,
        phoneNumber: "+91" + phoneNumber.value,
      };

    if (forAuth) {
      let res = await validateOTP({
        ...validateOtpReqBody,
        loginAfterVerify: true,
      });
      if (res.data?.success) {
        setCurrentUser(res.data?.data);

      }
    } else {
      let res = await validateOtpForModifyEmailOrPhone(validateOtpReqBody);
      if (res.data?.success) {
        onSuccess();
      }
    }
  };

  const resendOtp = async () => {
    let resendOtpReqBody = forAuth ? { signUp: false } : {};
    resendOtpReqBody = email
      ? { ...resendOtpReqBody, email: email.value }
      : {
        ...resendOtpReqBody,
        phoneNumber: "+91" + phoneNumber.value,
      };

    let res = await generateOTP(resendOtpReqBody);
    if (res.data?.success) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 60);
      restart(time);
    }
  };

  return (
    <FlexContainer direction="column">
      {forAuth ? (
        <ArrowBackIcon sx={backIconStyles()} onClick={() => navigate(-1)} />
      ) : null}
      <Typography sx={{ fontSize: "1.3rem" }}>ENTER OTP</Typography>
      <Box width="300px">
        <Typography color={"GrayText"} align="center" lineHeight={"15px"}>
          Please enter OTP sent to your
          {email?.value
            ? ` email ${email?.value}`
            : ` mobile number +91-xxxxxxxx${phoneNumber?.value?.slice(-2)}`}
        </Typography>
      </Box>
      <Spacer />
      <OtpInput
        value={otp}
        focusInput={1}
        shouldAutoFocus={true}
        onChange={(val) => {
          setError("");
          setOtp(val);
        }}
        focusStyle={{
          borderWidth: 1
        }}
        numInputs={6}
        inputStyle={{
          width: matches ? "30px" : "50px",
          height: matches ? "30px" : "55px",
          borderWidth: 1,
          borderRadius: 2,
        }}
        sx={{

          "&:focus": {
            backgroundColor: "red",
          },
          "&:hover": {
            backgroundColor: "green",
          },
        }}
        isInputNum={true}
        separator={<Spacer width={matches ? 1 : "10px"} />}
        className="custom-otp"
      />
      <Box sx={{ alignSelf: "flex-start", pl: 4, py: 1 }}>
        <FormError error={error} />
      </Box>
      <Spacer />
      <FlexContainer>
        <ButtonCustom
          onClick={onOtpSubmit}
          margin={forAuth ? "auto" : "0 1rem 0 0"}
        >
          <Box>Submit</Box>
        </ButtonCustom>
        {!forAuth ? (
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              borderRadius: "50px",
              "&:hover": { outline: "none" },
            }}
            onClick={revertChange}
          >
            Revert
          </Button>
        ) : null}
      </FlexContainer>
      <Spacer />
      <Typography color={"GrayText"}>
        {isRunning ? (
          <span>
            We can resend your otp in {minutes}:{seconds}
          </span>
        ) : (
          <>
            Haven’t received OTP yet?{" "}
            <span
              onClick={resendOtp}
              style={{
                color: "#005B64",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Resend OTP{" "}
            </span>
          </>
        )}
      </Typography>
    </FlexContainer>
  );
};

export default OtpView;
