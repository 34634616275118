import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const AlertMessageModal = (props) => {

  const { title, titleBtn, alertMessage, onClose, okbtnFlag, cancelBtnFlag, onClick } = props;


  return (

    <>
      <Dialog maxWidth='xs' fullWidth open={true} aria-labelledby='alertMessage_modal' aria-describedby='alertMessage_modal_descp' style={{ padding: "50px" }}>

        {titleBtn &&
          <DialogTitle id='alertMessage_modal' style={{ backgroundColor: '#82D2DA' }}>
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={12} container alignItems='center' justifyContent='center'>
                <DoneIcon color='success' fontSize='large' />
                <Typography style={{ fontSize: '20px', marginLeft: '5px', fontWeight: 600, textAlign: 'center', display: 'inline' }}>{title}</Typography>
              </Grid>
            </Grid>

          </DialogTitle>
        }

        <DialogContent style={{ marginTop: '20px' }}>
          <DialogContentText id='alertMessage_modal_descp'>
            <Grid container spacing={2} style={{ backgroundColor: 'lightgray', borderRadius: '5px' }}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>{alertMessage}</Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ marginBottom: 20 }}>
          {
            okbtnFlag &&
            <Button variant="contained" component="span" style={{ borderRadius: '50px', width: '80px', fontSize: '12px', backgroundColor: '#82D2DA', boxShadow: 5, fontWeight: 600, margin: 'auto', outline: 'none' }} onClick={onClick} >Ok</Button>
          }
          {
            cancelBtnFlag &&
            <Button variant="contained" component="span" style={{ borderRadius: '50px', width: '80px', fontSize: '12px', backgroundColor: '#82D2DA', boxShadow: 5, fontWeight: 600, margin: 'auto', outline: 'none' }} onClick={onClose}>Cancel</Button>
          }

        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertMessageModal;