import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import GlobalLayout from "../../components/layout/GlobalLayout";
import useRoleHelper from "../../hooks/useRoleHelper";

const pre = {
    'SCHOOL EVENTS':
        [{ title: 'SCHOOL EVENTS', privilege: 'UPDATE' },
        { title: 'SCHOOL EVENTS', privilege: 'REPORTS' },
        { title: 'SCHOOL EVENTS', privilege: 'CREATE' },
        { title: 'SCHOOL EVENTS', privilege: 'VIEW' }],
    DASHBOARD:
        [{ title: 'DASHBOARD', privilege: 'CHILDREN SCHOOL' },
        { title: 'DASHBOARD', privilege: 'VIEW' },
        { title: 'DASHBOARD', privilege: 'UPDATE' }],
    COURSES:
        [{ title: 'COURSES', privilege: 'CHILDREN SCHOOL' },
        { title: 'COURSES', privilege: 'VIEW' },
        { title: 'COURSES', privilege: 'EVALUATION' },
        { title: 'COURSES', privilege: 'CREATE' },
        { title: 'COURSES', privilege: 'UPDATE' },
        { title: 'COURSES', privilege: 'REPORTS' },
        { title: 'COURSES', privilege: 'DOWNLOAD' }],
    STUDENTS:
        [{ title: 'STUDENTS', privilege: 'CHILDREN SCHOOL' },
        { title: 'STUDENTS', privilege: 'VIEW' },
        { title: 'STUDENTS', privilege: 'CREATE' },
        { title: 'STUDENTS', privilege: 'UPDATE' },
        { title: 'STUDENTS', privilege: 'DOWNLOAD' },
        { title: 'STUDENTS', privilege: 'MENTOR' }],
    CONTENT:
        [{ title: 'CONTENT', privilege: 'VIEW' },
        { title: 'CONTENT', privilege: 'CREATE' },
        { title: 'CONTENT', privilege: 'UPDATE' }],
    ASSESSMENT:
        [{ title: 'ASSESSMENT', privilege: 'VIEW' },
        { title: 'ASSESSMENT', privilege: 'CREATE' },
        { title: 'ASSESSMENT', privilege: 'UPDATE' }],
    'QUESTION BANK':
        [{ title: 'QUESTION BANK', privilege: 'VIEW' },
        { title: 'QUESTION BANK', privilege: 'CREATE' },
        { title: 'QUESTION BANK', privilege: 'UPDATE' }],
    CERTIFICATES:
        [{ title: 'CERTIFICATES', privilege: 'VIEW' },
        { title: 'CERTIFICATES', privilege: 'CREATE' },
        { title: 'CERTIFICATES', privilege: 'UPDATE' }],
    FEEDBACK:
        [{ title: 'FEEDBACK', privilege: 'VIEW' },
        { title: 'FEEDBACK', privilege: 'CREATE' },
        { title: 'FEEDBACK', privilege: 'UPDATE' },
        { title: 'FEEDBACK', privilege: 'DELETE' }],
    REQUESTS:
        [{ title: 'REQUESTS', privilege: 'VIEW' },
        { title: 'REQUESTS', privilege: 'UPDATE' }],
    ADMIN:
        [{ title: 'ADMIN', privilege: 'VIEW' },
        { title: 'ADMIN', privilege: 'CREATE' },
        { title: 'ADMIN', privilege: 'UPDATE' }],
    DISCUSSIONS:
        [{ title: 'DISCUSSIONS', privilege: 'VIEW' },
        { title: 'DISCUSSIONS', privilege: 'UPDATE' }],
    'SUB SCHOOL':
        [{ title: 'SUB SCHOOL', privilege: 'VIEW' },
        { title: 'SUB SCHOOL', privilege: 'CREATE' },
        { title: 'SUB SCHOOL', privilege: 'UPDATE' },
        { title: 'SUB SCHOOL', privilege: 'REPORTS' },
        { title: 'SUB SCHOOL', privilege: 'DOWNLOAD' }]
}
const EditRole = () => {
    const navigate = useNavigate();
    const { getRole, saveRole } = useRoleHelper();
    const { roleId } = useParams()
    const [roleName, setRoleName] = useState();
    const [privileges, setPrivileges] = useState([]);
    const [countPerPage] = useState(5);
    const [page] = useState(1);
    const { data: roleData, isLoading: userLoader } = useQuery([`RoleData`, page, countPerPage], () => getRole(roleId), { enabled: true, retry: false })

    useEffect(() => {
        if (roleData) {
            console.log(roleData?.data?.data.privileges)
            setPrivileges(roleData?.data?.data.privileges)
            setRoleName(roleData?.data?.data.roleTitle)
        }
    }, [roleData])

    const getChecked = (title,privilege) => {
        if(privileges){
            let ele = privileges.filter((value, indes, arr) => {
                return value.title === title && value.privilege === privilege;
            })
            return ele.length>0;
        }
    }

    const handleChange = (e, title, privilege) => {
        if (e.target.checked) {
            console.log(privileges)
            if(privileges==null){
                setPrivileges([                
                    {
                    "title": title,
                    "privilege": privilege
                    }
                ])
            } else {
                setPrivileges((prev) => [
                    ...prev,
                    {
                        "title": title,
                        "privilege": privilege
                    }
                ])
            }
        } else {
            setPrivileges(
                privileges.filter((value, index, arr) => {
                    return !(value.title === title && value.privilege === privilege);
                })
            )
        }
    }

    const onSubmit = async () => {
        console.log(roleName);
        console.log(privileges);
        let resp = await saveRole({
            privileges:privileges,
            roleTitle:roleName
        },roleId);
        if(resp?.data?.success){
            navigate("/roles")
        }

    }
    return (
        <GlobalLayout loader={userLoader}>
            <Grid   container
                direction="column"
                justifyContent="center"
                alignItems="center" m={2} pr={3} sx ={{paddingLeft:"-100px"}}style={{ marginTop: 110 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Role Name"
                    value={roleName}
                    sx={{
                        maxWidth: "200px"
                    }}
                    onChange={(e) => setRoleName(e.target.value)}
                />
                <Stack >
                    
                    {Object.keys(pre).map((label) => {
                        return (
                            <Paper key={label} sx={{ margin: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
                                <FormControl component="fieldset" margin="normal">
                                    <FormLabel component="legend" focused={false}>
                                        {label}
                                    </FormLabel>
                                    <FormGroup aria-label="Temas" row={true}>
                                        {pre[label].map((action, i) => (
                                            <FormControlLabel
                                                key={i}
                                                control={<Checkbox />}
                                                label={action.privilege}
                                                checked = {getChecked(label, action.privilege)}
                                                onChange={(e) => handleChange(e, label, action.privilege)}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </Paper>
                        )
                    })}
                </Stack>
                <Button variant="contained" onClick={onSubmit}>Save</Button>
            </Grid >

        </GlobalLayout>
    );
};

export default EditRole;