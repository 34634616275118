import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

const usePaymentHelper = () => {
    const getCourses = async (type) => {
        let endpoint = environment.paymentUrl + Endpoints.GET_COURSES;
        if(type){
            endpoint+=`?type=${type}`;
        }
        return await axiosInstance(false).get(endpoint);
    };
    const  createPayment = async (body) => {
        const endpoint = environment.paymentUrl + Endpoints.PAYMENT_INFO;
        return await axiosInstance(false).post(endpoint,body);
    };

    const getSubscribtions = async (userId) => {
        const endpoint = environment.paymentUrl + Endpoints.SUBSCRIPTIONS+userId;
        return await axiosInstance(false).get(endpoint);
    }

    const getPayments = async (userId) => {
        const endpoint = environment.paymentUrl + Endpoints.PAYMENTS+userId;
        return await axiosInstance(false).get(endpoint);
    }
 
    return {
        getCourses,
        createPayment,
        getSubscribtions,
        getPayments
    };
};

export default usePaymentHelper;