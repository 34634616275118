import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";
import { convertDatainString } from "./useFeatureHelper";

const useUsersHelper = () => {
    const getUsers = async (body) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + 'all' + convertDatainString({ ...body });
        return await axiosInstance(false).get(endpoint);
    };
    const getUsersGrade = async (body) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + 'all' + convertDatainString({ ...body });
        return await axiosInstance(false).get(endpoint);
    };

    const createUser = async (body) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN;
        return await axiosInstance().post(endpoint, body);
    }        
    const inviteOneUser = async (body) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + Endpoints.INVITE+"User";
        return await axiosInstance().post(endpoint, body);
    }        
    const updateUser = async (id,body) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + id;
        return await axiosInstance().put(endpoint,body);
    }
    const deleteUser = async (id) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + id;
        return await axiosInstance().delete(endpoint);
    };  

    const deleteCoordinator = async (id) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + id;
        return await axiosInstance().delete(endpoint);
    };  

    const inviteAll = async (schoolId,grade) => {
        const endpoint = environment.userBaseUrl + Endpoints.ADMIN + Endpoints.INVITE +`/all?schoolId=${schoolId}&grade=${grade}`;
        return await axiosInstance().get(endpoint);
    }

    const uploadFile = async (body) => {
        const endpoint = environment.userBaseUrl+ Endpoints.UPLOAD_FILE;
        return await axiosInstance().post(endpoint, body);
    }
    const importFile = async (fileUrl) => {
        const endpoint = environment.userBaseUrl+Endpoints.IMPORT_FILE+`?fileUrl=${fileUrl}`;
        return await axiosInstance().post(endpoint);
    }

    const getGrades = async (type) => {
        const endpoint = environment.userBaseUrl+Endpoints.GRADE+`?type=${type}`;
        return await axiosInstance().get(endpoint);
    }
    const importReport = async (formdata) => {
        const endpoint = environment.reports+ "/report";
        return await axiosInstance().post(endpoint, formdata);
    }
    return {
        getUsers,
        createUser,
        inviteOneUser,
        deleteUser,
        uploadFile,
        importFile,
        updateUser,
        inviteAll,
        getUsersGrade,
        getGrades,
        importReport
    };
};

export default useUsersHelper;