import { useCallback, useEffect, useRef, useState } from "react";
import useAsyncDebounce from "./useAsyncDebounce";
import { isNumeric } from "validator";

export function useField({
  initialValue = "",
  initialTouched = false,
  initialDirty = false,
  initialMeta = {},
  initialValid = false,
  validate = () => false,
  validatePristine = false,
  inputType = "text",
} = {}) {
  const [value, setValue] = useState(initialValue);
  const [isTouched, setTouched] = useState(initialTouched);
  const [isDirty, setDirty] = useState(initialDirty);
  const [meta, setMeta] = useState(initialMeta);
  const [isValid, setValid] = useState(initialValid);
  const [isValidating, setValidating] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showError, setShowError] = useState(false);

  const latestValidateRef = useRef();
  const apiRef = useRef();
  const validationCountRef = useRef(0);

  latestValidateRef.current = validate;

  const getInputProps = useCallback(
    (userProps = {}) => {
      return {
        value,
        onChange: (e) => {
          setDirty(true);
          setShowError(false);
          if (
            inputType === "number" &&
            !!e.target.value &&
            !isNumeric(e.target.value?.trim())
          )
            return;
          setValue(e.target.value);
        },
        onBlur: (e) => {
          setIsFocused(false);
          setTouched(true);
          setValue(e.target.value?.trim());
        },
        onFocus: (e) => {
          setIsFocused(true);
        },
        ...userProps,
      };
    },
    [value, inputType]
  );

  apiRef.current = {
    value,
    isTouched,
    isDirty,
    meta,
    isValid,
    isValidating,
    validationCount: validationCountRef.current,
    setValue,
    setDirty,
    setMeta,
    setValid,
    setValidating,
    getInputProps,
    isFocused,
    showError,
    setShowError,
  };

  const debounce = useAsyncDebounce();

  apiRef.current.runValidation = useCallback(async () => {
    setValidating(true);

    const id = validationCountRef.current + 1;
    validationCountRef.current = id;

    const checkLatest = () => id === validationCountRef.current;

    const wrapForLatest =
      (fn) =>
      (...args) => {
        if (!checkLatest()) {
          return;
        }
        return fn(...args);
      };

    const error = await latestValidateRef.current({
      ...apiRef.current,
      setValue: wrapForLatest(apiRef.current.setValue),
      setDirty: wrapForLatest(apiRef.current.setDirty),
      setMeta: wrapForLatest(apiRef.current.setMeta),
      setValid: wrapForLatest(apiRef.current.setValid),
      debounce,
    });

    if (checkLatest()) {
      setValidating(false);
      if (typeof error !== "undefined") {
        if (error) {
          if (typeof error === "string") {
            setMeta((old) => ({ ...old, error }));
          }
          setValid(false);
        } else {
          setMeta((old) => ({ ...old, error: undefined }));
          setValid(true);
        }
      }
    }
  }, [debounce]);

  useEffect(() => {
    // if (!validatePristine && !isDirty) {
    //   return;
    // }

    apiRef.current.runValidation(value);
  }, [validatePristine, isDirty, value]);

  return apiRef.current;
}
