import { Box, Button, Grid, Typography } from "@mui/material";
import GlobalLayout from "../../components/layout/GlobalLayout";
import { useNavigate } from "react-router-dom";

const NoteBook = () => {
    const navigate = useNavigate();
    return (
        <GlobalLayout>
            <Grid  container m={2} pr={24} style={{ marginTop: 100,display:'flex',flexDirection:'row' }}>
                <Grid>
                    <Box onClick={() => {navigate('/schools')}} onhover="Green"
                        style={{backgroundColor:'#4545A5',borderRadius:'15px',height:'350px',width:'650px',textAlign:'center',alignItems:'center',justifyContent:'center',display:'flex'}}>
                            <Typography style={{color:'white',alignItems:'center',justifyContent:'center'}}>SCHOOLS</Typography>
                        
                        </Box>
                        <Box onClick={() => {navigate('/students')}} onhover=""
                        style={{backgroundColor:'#4545A5',borderRadius:'15px',height:'350px',width:'650px',textAlign:'center',alignItems:'center',justifyContent:'center',display:'flex',marginLeft:'110%',marginTop:'-54%'}}>
                        <Typography style={{color:'white',alignItems:'center',justifyContent:'center'}}>STUDENTS</Typography>
                        </Box>
                </Grid>
                <Grid>
                    <Box onClick={() => {navigate('/Users')}} onhover=""
                      style={{backgroundColor:'#4545A5',borderRadius:'15px',height:'350px',width:'650px',textAlign:'center',alignItems:'center',justifyContent:'center',display:'flex',marginTop:'2%'}}>
                      <Typography style={{color:'white',alignItems:'center',justifyContent:'center'}}>USERS</Typography>
                    </Box>
                    <Box onClick={() => {""}} onhover="yellow"
                        style={{backgroundColor:'#4545A5',borderRadius:'15px',height:'350px',width:'650px',textAlign:'center',alignItems:'center',justifyContent:'center',display:'flex',marginLeft:'110%',marginTop:'-54%'}}>
                        <Typography style={{color:'white',alignItems:'center',justifyContent:'center'}}>ADMIN</Typography>
                        </Box>
                 </Grid>               
               
                    
               
            </Grid>
        </GlobalLayout>
    )
}
export default NoteBook;