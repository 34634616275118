import React, { useEffect } from "react";
import MaterialTable from "material-table";
import {
	Button,
	getNativeSelectUtilityClasses,
	Grid,
	Link,
	CircularProgress,
	Backdrop,
	Tooltip,
	Typography,
	IconButton,
	Icon,
	Popover,
} from "@mui/material";
import GlobalLayout from "../../components/layout/GlobalLayout";
import AlertMessageModal from "../../components/attention/AlertMessageModal";
import { useState } from "react";
import { useMutation } from "react-query";
import { CustomSnackbar } from "../../components/elements/CustomSnackbar";
import useSchoolHelper from "../../hooks/useSchoolHelper";
import useAdminHelper from "../../hooks/useAdminHelper";
import { useStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import { environment } from "../../environments/environment";
import useSessionHelper from "../../hooks/useSessionHelper";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { useQuery } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/system";
import { SCHOOL_ID } from "../../constants/constants";
import axios from "axios";
import { ArrowDownward, ArrowUpward, CloudUpload, ListAltOutlined } from "@mui/icons-material";

const Schools = () => {
	const [snakeBarProps, setSnakeBarProps] = useState({});
	const navigate = useNavigate();
	const [tableArr, setTableArr] = React.useState([]);
	const [countPerPage, setCountPerPage] = useState(5);
	const [tableTotalCount, setTableTotalCount] = useState(1);
	const [file,setFile] = useState()
	const [page, setPage] = useState(1);
	let currentUser = useStore((state) => state.currentUser);
	const { getAllSchools, deleteSchool } = useSchoolHelper();
	const { getMedals, getAwards, postWeight } = useSessionHelper();
	const [delMessage, setDelMessage] = React.useState(false);
	const [currentRowId, setCurrentRowId] = React.useState(null);
	const [loading, setLoading] = useState(false);
	const {
		data: schoolsData,
		isLoading: schoolLoader,
		refetch: schoolsRefetch,
	} = useQuery([`SchoolsData`], () => getAllSchools(), {
		enabled: true,
		retry: false,
	});

	const { data: schoolAwardsData, refetch } = useQuery(
		[`SchoolAwardsData`],
		() => getAwards(),
		{ enabled: !!schoolsData, retry: false }
	);

	const {
		data: MedalsData,
		isLoading: medalsLoader,
		refetch: medalsRefetch,
	} = useQuery([`MedalsData`], () => getMedals(), {
		enabled: false,
		retry: false,
	});

	const [syncData, setSyncData] = useState({});

	const [weight, setWeight] = useState(() => {
		const storedWeight = localStorage.getItem("weight");
		return storedWeight !== null ? parseInt(storedWeight, 10) : 0;
	});

	useEffect(() => {
		if (schoolsData?.data?.data) {
			let tempArr = schoolsData?.data?.data;
			let awardMap = new Map();
			if (schoolAwardsData?.data?.data) {
				schoolAwardsData?.data?.data?.forEach((element) => {
					awardMap.set(element["schoolId"], element);
				});
				tempArr = tempArr.map((ele) => {
					if (awardMap.has(ele.id)) {
						let value = awardMap.get(ele.id);
						ele["numGold"] = value["numGold"];
						ele["numSilver"] = value["numSilver"];
						ele["numCertificate"] = value["numCertificate"];
						ele["numTopper"] = value["numTopper"];
						ele["weight"] = value["weight"];
					} else {
						ele["numGold"] = 0;
						ele["numSilver"] = 0;
						ele["numCertificate"] = 0;
						ele["numTopper"] = 0;
						ele["weight"] = 10.0;
					}
				});
			}
			setTableArr(schoolsData?.data?.data);
			setTableTotalCount(schoolsData?.data?.data?.length);
		}
	}, [schoolsData, schoolAwardsData]);

	const { getSync } = useSessionHelper();
	const { importReport } = useAdminHelper();

	const handleSyncClick = (schoolId) => {
		getSync(schoolId).then((resp) => {
			let syncDataResp = resp?.data?.data;
			console.log(syncData);
			let temp = syncData;
			let tempCounts = {
				nativeCount: 0,
				commonCount: 0,
				remoteCount: 0,
			};
			Object.values(syncDataResp).forEach((counts) => {
				tempCounts["remoteCount"] += counts["remoteCount"];
				tempCounts["commonCount"] += counts["commonCount"];
				tempCounts["nativeCount"] += counts["nativeCount"];
			});
			temp[schoolId] = JSON.stringify(tempCounts);
			setSyncData((prev) => temp);
			setCurrentRowId(schoolId);
			console.log(syncData);
		});
	};

	const handleFileChange = async (event) => {
		let file = event.target.files[0]
		const formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        )
		let res = await importReport(formData);
		if (res?.status === 200) {
            console.log(res)   
        }
	}

	const handleHallticketClick = (centerId) => {
		window.open(
			`https://reports.liveolympiad.org/hall_tickets.php?center=${centerId}&env=${environment.env}`
		);
	};
	const deleteRow = async () => {
		let res = await deleteSchool(currentRowId);
		schoolsRefetch();
		refetch();
		if (res?.data?.success) {
			setDelMessage(false);
		} else {
			setDelMessage(false);
		}
	};
	const { mutate: deleteMutate } = useMutation(deleteSchool, {
		onSuccess: (data, variables, context) =>
			onSuccessUserDelete(data, variables, context),
		onError: (data, variables, context) =>
			onErrorUserDelete(data, variables, context),
	});
	const onSuccessUserDelete = (data, variables, context) => {
		if (data) {
			setDelMessage(false);
			setSnakeBarProps({
				snackbarFlag: true,
				msz: data.data.message,
				type: "success",
			});
		}
	};
	const [userRoles, setUserRoles] = useState();

	useEffect(() => {
		var userInfoVal = window.localStorage.getItem("current_user");
		if (userInfoVal) {
			var userInfo = JSON.parse(userInfoVal).state.currentUser;
			setUserRoles(userInfo.userRole);
		}
	});

	const onErrorUserDelete = (error, variables, context) => {
		error &&
			setSnakeBarProps({
				snackbarFlag: true,
				msz: error.message,
				type: "error",
			});
	};
	var userInfoVal = window.localStorage.getItem("current_user");

	const openInNewTab = (url) => {
		window.open(url, "_blank", "noreferrer");
	  };

	const onWeightChange = async (id, weight) => {
		try {
			const response = await postWeight({
				weights: [
					{
						schoolId: id,
						weight: weight,
					},
				],
			});
			console.log(response.data);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};

	const handleWeightClick = (id, weight) => {
		setWeight(weight);
		onWeightChange(id, weight);
		localStorage.setItem("weight", weight);
	};

	return (
		<GlobalLayout loader={schoolLoader}>
			<Grid container m={2} pr={3} style={{ marginTop: 50, overflow: "auto" }}>
				<Box sx={{display:"flex", alignItems:'center',justifyContent:"flex-end", gap:"10px", width:"100%"}}>
				<Button
						onClick={() => {
							if (userRoles !== "SCHOOL_ADMIN") {
								navigate("/school");
							}
						}}
						style={{
							borderRadius: "50px",
							color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
							textDecoration: "none",
						}}
						variant="contained"
					>
						Add New School
					</Button>
					<Box sx={{alignItems:"center"}}>
					<label htmlFor="file-upload-input">
						<Button sx={{borderRadius:"20px",mt:"2px"}}component="span" variant="contained" color="primary" startIcon={<CloudUpload />}>
							Upload Report
						</Button>
						</label>
						<input
							id="file-upload-input"
							type="file"
							hidden
							onChange={handleFileChange}
						/>
					</Box>
					
				</Box>
					
					
				<Grid item xs={12} sx={{ marginTop: 2, overflow: "auto" }}>
					{}
					<MaterialTable
						columns={[
							{ title: "School Name", field: "name" },
							{ title: "School Code", field: "schoolcode" },
							{ title: "State", field: "state" },

							{
								title: "Students ",
								field: "studentCount",
								render: (rowData) => {
									return (
										<Grid container>
											<Grid item>
												<Button>
													<Link href={"/students?schoolId=" + rowData._id}>
														{rowData.studentCount ?? 0}
													</Link>
												</Button>
											</Grid>
										</Grid>
									);
								},
							},
							{
								title: "Subscriptions",
								field: "subscriptionCount",
								render: (rowData) => {
									return (
										<Grid container>
											<Grid item>
												<Button>
													<Link href={"/students?schoolId=" + rowData._id}>
														{rowData.subscriptionCount ?? 0}
													</Link>
												</Button>
											</Grid>
										</Grid>
									);
								},
							},
							// { title: "Gold Medals", field: "numGold" },
							// { title: "Silver Medals", field: "numSilver" },
							// { title: "Certificate", field: "numCertificate" },
							// { title: "Toppers", field: "numTopper" },
							{
								title: "Classes ",
								field: "gradeCount",
								render: (rowData) => {
									return (
										<Grid container>
											<Grid item>
												<Button onClick={() => {navigate("/classes?schoolId=" + rowData._id)}}>
													<Link
														style={{ marginRight: "5px" }}
													>
														{rowData.gradeCount ?? 0}
													</Link>
												</Button>
												{/* {environment.env !== "live" && (
													<>
														{syncData[rowData._id] != null ? (
															Object.values(
																JSON.parse(syncData[rowData._id])
															).join(",")
														) : (
															<Tooltip title={"Get Latest Sync Data"}>
																<Button
																	style={{ marginRight: "5px" }}
																	onClick={() => handleSyncClick(rowData._id)}
																>
																	<SyncIcon />
																</Button>
															</Tooltip>
														)}
													</>
												)} */}
											</Grid>
										</Grid>
									);
								},
							},
							{
								title: "Coordinators",
								field: "status",
								render: (rowData) => {
									return (
										<Grid container>
											<Grid item>
												<Button>
													<Link
														style={{ marginRight: "5px" }}
														href={"/coordinators?schoolId=" + rowData._id}
													>
														{rowData.coordinatorCount ?? 0}
													</Link>
												</Button>
											</Grid>
										</Grid>
									);
								},
							},
							// {
							// 	title: "Weight",
							// 	field: "weight",
							// 	render: (rowData) => {
							// 		return (
							// 			<Grid container>
							// 				<Grid item>
							// 					<Box
							// 						style={{
							// 							display: "flex",
							// 							border: "1px solid black",
							// 							borderRadius: "5px",
							// 							p: 2,
							// 						}}
							// 					>
							// 						<IconButton
							// 							color="secondary"
							// 							disabled={
							// 								rowData.weight == 10 &&
							// 								userRoles !== "SUPER_ADMIN"
							// 							}
							// 							onClick={() =>
							// 								handleWeightClick(rowData._id, rowData.weight + 1)
							// 							}
							// 						>
							// 							<AddIcon
							// 								style={{
							// 									color:
							// 										userRoles !== "SUPER_ADMIN" ? "" : "#2596be",
							// 								}}
							// 							/>
							// 						</IconButton>
							// 						<Box
							// 							style={{ display: "flex", alignItems: "center" }}
							// 						>
							// 							<Typography
							// 								sx={{ textAlign: "center", p: 1 }}
							// 								href={"/students?schoolId=" + rowData._id}
							// 							>
							// 								{rowData.weight}
							// 							</Typography>
							// 						</Box>
							// 						<IconButton
							// 							color="secondary"
							// 							disabled={
							// 								rowData.weight == 0 && userRoles !== "SUPER_ADMIN"
							// 							}
							// 							onClick={() =>
							// 								handleWeightClick(rowData._id, rowData.weight - 1)
							// 							}
							// 						>
							// 							<RemoveIcon
							// 								style={{
							// 									color:
							// 										userRoles !== "SUPER_ADMIN" ? "" : "#2596be",
							// 								}}
							// 							/>
							// 						</IconButton>
							// 					</Box>
							// 				</Grid>
							// 			</Grid>
							// 		);
							// 	},
							// },
							{
								title: "Actions",
								field: "status",
								render: (rowData) => {
									return (
										<Box
											sx={{
												flexDirection: "row",
												display: "flex",
												alignItems: "left",
												justifyContent: "left",
											}}
										>
											<IconButton
												color="primary"
												sx={{
													color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
													textDecoration: "none",
												}}
												onClick={() => {
													if (userRoles !== "SCHOOL_ADMIN") {
														setDelMessage(true);
														setCurrentRowId(rowData._id);
													}
												}}
											>
												<DeleteIcon />
											</IconButton>
											<IconButton
												color="primary"
												sx={{
													color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
													textDecoration: "none",
												}}
												onClick={() => {
													if (userRoles !== "SCHOOL_ADMIN") {
														navigate("/school?schoolId=" + rowData._id);
													}
												}}
											>
												<EditIcon />
											</IconButton>
											<IconButton
												color="primary"
												sx={{
													color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
													textDecoration: "none",
												}}
												onClick={() => {
													openInNewTab(`${environment.reports}/attendance?series=L-1&school=${rowData["name"]}`)
												}}
											>
												<ListAltOutlined />
											</IconButton>
											<IconButton
												color="primary"
												sx={{
													color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
													textDecoration: "none",
												}}
												onClick={() => {
													if (userRoles !== "SCHOOL_ADMIN") {
														navigate("/school?schoolId=" + rowData._id);
													}
												}}
											>
												<ArrowDownward/>
											</IconButton>
											{/* <IconButton
												color="primary"
												onClick={() => handleHallticketClick(rowData._id)}
											>
												<LocalActivityIcon />
											</IconButton> */}
										</Box>
									);
								},
							},
						]}
						totalCount={tableTotalCount}
						data={tableArr}
						title="Schools"
						options={{
							pageSize: 100,
							headerStyle: {
								backgroundColor: "#82D2DA",
							},
						}}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				{delMessage && (
					<AlertMessageModal
						setDelMessage={setDelMessage}
						alertMessage="Do you want to remove the school?"
						okbtnFlag="true"
						cancelBtnFlag="true"
						onClose={() => {
							setDelMessage(false);
						}}
						onClick={() => {
							deleteRow();
						}}
					/>
				)}
			</Grid>

			{Object.keys(snakeBarProps).length > 0 && (
				<CustomSnackbar
					{...snakeBarProps}
					setSnakeBarProps={setSnakeBarProps}
				/>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{Object.keys(snakeBarProps).length > 0 && (
				<CustomSnackbar
					{...snakeBarProps}
					setSnakeBarProps={setSnakeBarProps}
				/>
			)}
		</GlobalLayout>
	);
};

export default Schools;
