import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/system";

const FormError = ({ error }) => {
  const theme = useTheme();
  return (
    <span
      style={{
        paddingLeft: "1rem",
        color: theme.palette.error.main,
        fontSize: ".8rem",
      }}
    >
      {error ? <InfoIcon fontSize="small" /> : null}
      {error ? " " + error : null}
    </span>
  );
};

export default FormError;
