import { Box, Card, InputAdornment, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useOnboardingHelper from "../../hooks/useOnboardingHelper";
import { useField } from "../../hooks/useField";
import { isRequired } from "../../utils/validators";
import BasicDatePicker from "../../components/elements/DatePicker";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import ProfileFormFooter from "./ProfileFormFooter";
import { useFileUpload } from "use-file-upload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Avatar, Grid } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Router } from "react-router";

import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { ProfileWrapper } from "./ProfileWrapper";
import ButtonCustom from "../../components/elements/ButtonCustom";
import CustomModal from "../../components/elements/CustomModal";

import usePaymentHelper from "../../hooks/usePaymentHelper";
import useSchoolHelper from "../../hooks/useSchoolHelper";

const HorizontalSpacer = () => <Spacer width={1} />;
const VerticalSpacer = () => <Spacer height={2} />;
const Education = ({ steps, index, handleBack, handleNext, studentId }) => {
	const query = new URLSearchParams(window.location.search);
	const schoolId = query.get("school");

	const { getSchoolInfo } = useSchoolHelper();
	const { data: schoolData } = useQuery(
		["School", schoolId],
		() => getSchoolInfo(schoolId),
		{ enabled: !!schoolId }
	);

	const { createPayment, getCourses, getPayments } = usePaymentHelper();

	const navigate = useNavigate();

	const [isFirstTime, setIsFirstTime] = useState(true);
	const [infoId, setInfoId] = useState("");
	// const [file, selectFile] = useFileUpload();
	// const [existingCert, setExistingCert] = useState();
	const theme = useTheme();
	const [pageData, setPageData] = useState([]);
	const [infoArr, setInfoArr] = useState([]);
	const [addMore, setAddMore] = useState(false);
	const [expandedIndex, setExpandedIndex] = useState(-1);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [deleteFlag, setDeleteFlag] = useState(false);
	const [CourseList, setCourseList] = useState([]);
	const [amount, setAmount] = useState(300);
	const [courseLists, setList] = useState([]);
	const [paymentType, setPaymentType] = useState();
	const [paymentStatus, setPaymentStatus] = useState();

	useEffect(() => {
		let count = courseLists.length;
		if (count == 1) setAmount(300);
		else {
			setAmount(count * 300 - (count - 1) * 50);
			console.log("amount>>>", amount);
		}
	}, [courseLists]);

	useEffect(() => {
		if (schoolData) {
			getCourses(schoolData?.data?.data?.type).then((res) => {
				console.log(res?.data?.data);
				setCourseList(res?.data?.data);
			});
		}
	}, [schoolData]);

	const {
		isLoading: isEducationLoading,
		isError: isEducationError,
		data: CoursesData,
		refetch,
	} = useQuery("Courses", () => getCourses(schoolData?.data?.data?.type), {
		enabled: !!schoolData?.data?.data?.type,
		refetchOnWindowFocus: false,
	});

	const {
		isLoading: isPayments,
		isError: isPaymentsError,
		data: PaymentsData,
		paymentRefetch,
	} = useQuery("Payments", () => getPayments(studentId), {
		refetchOnWindowFocus: false,
	});

	const onSubmit = async () => {
		if (amount > 0 && courseLists.length > 0) {
			let courseIds = [];
			CoursesData?.data?.data?.forEach((element) => {
				if (courseLists.includes(element.name)) {
					courseIds.push(element.id);
				}
			});
			let res = await createPayment({
				amount: amount * 100,
				courseIds: courseIds,
				userId: studentId,
			});
			if (res.data.success) {
				console.log("payment>>", res.data);
			}
		}

		Router.History.back();
	};

	useEffect(() => {
		if (PaymentsData) {
			var localArr = PaymentsData.data.data.map((option) => ({
				info1: option["courseIds"],
				info2: option["amount"] + " " + option["currency"],
				info3: option["processedBy"],
			}));
			setInfoArr(localArr);
			setPageData(PaymentsData.data.data);
			setIsFirstTime(PaymentsData.data.data.length == 0 ? true : false);
			console.log("localArr");
		}
	}, [PaymentsData]);

	// useEffect(() => {
	//   if (addMore) {
	//     setPageData([...pageData, {}])
	//     setExpandedIndex('panel_' + pageData.length);
	//     setCurrentIndex(pageData.length + 1)
	//   } else {
	//     refetch();
	//   }
	// }, [addMore])

	useEffect(() => {
		// setAddMore(false)
		if (pageData.length > 0) {
			var index = currentIndex;
			console.log("currentIndex :>> ", currentIndex);
			console.log("pageData :>> ", pageData);
			setIsFirstTime(pageData[index] ? false : true);
			setInfoId(pageData[index]?.["id"] ? pageData[index]?.["id"] : "");
			setPaymentType(
				pageData[index]?.["paymentType"] ? pageData[index]?.["paymentType"] : ""
			);
			setPaymentStatus(
				pageData[index]?.["status"] ? pageData[index]?.["status"] : ""
			);
			setAmount(pageData[index]?.["amount"] ? pageData[index]?.["amount"] : "");
			setList(
				pageData[index]?.["courseIds"]
					? pageData[index]?.["courseIds"].split(",")
					: ""
			);
			// attachment.setValue("");
			// setExistingCert("");
		}
	}, [pageData, currentIndex]);

	const handleCourseChange = (event) => {
		setList(event.target.value);
		//        let len =  event.target.value.length
	};
	return (
		<>
			<ProfileWrapper
				pageArr={pageData}
				infoArr={infoArr}
				expandedIndex={expandedIndex}
				setExpandedIndex={setExpandedIndex}
				setCurrentIndex={setCurrentIndex}
				parent="Payment"
			>
				<Box>
					<Box margin="auto" width="600px">
						<Input
							label="Courses*"
							select
							// {...school.getInputProps()}
							options={CourseList?.map((school) => ({
								value: school.name,
								label: school.name,
							}))}
							SelectProps={{
								multiple: true,
								value: courseLists,
								onChange: handleCourseChange,
							}}
							width="593px"
						/>
						<span style={{ color: "red", fontWeight: "bold" }}>*</span>
						<VerticalSpacer />
						<Input
							label="Amount"
							value={amount}
							onChange={(event) => {
								setAmount(event.target.value);
							}}
							// {...grade.getInputProps()}
							width="593px"
							disabled
						/>
						<VerticalSpacer />
						<Input
							label="Payment Status"
							maxChar={100}
							// {...section.getInputProps()}
							value={paymentStatus}
							width="593px"
						/>
						<VerticalSpacer />
						<Input
							label="Payment Type"
							maxChar={100}
							// {...section.getInputProps()}
							value={paymentType}
							width="593px"
						/>
						<VerticalSpacer />
					</Box>
				</Box>

				<Box my={3}>
					<Grid container spacing={1} justifyContent="space-between">
						<Grid item xs={3}>
							<ButtonCustom onClick={onSubmit} mt="0">
								Save
							</ButtonCustom>
						</Grid>
					</Grid>
				</Box>
			</ProfileWrapper>
			<Box my={3}>
				<ProfileFormFooter
					steps={steps}
					index={index}
					handleNext={handleNext}
					handleBack={handleBack}
				/>
			</Box>
			{deleteFlag && (
				<CustomModal
					open={deleteFlag}
					setOpen={setDeleteFlag}
					closeOnOutsideClick={false}
				>
					<Card
						sx={{
							px: 6,
							pt: 7,
							pb: 3,
							width: "45vw",
							position: "relative",
							margin: "auto",
							mt: "10vh",
						}}
					>
						<Box width={1} mb={3} textAlign="center">
							<DeleteOutlineIcon sx={{ fontSize: "80px", color: "red" }} />
						</Box>
						<Typography>
							<Box width={1} mb={3} textAlign="center">
								Are Sure you want to delete this column ?
							</Box>
						</Typography>
						<Grid container spacing={1} justifyContent="flex-end">
							<Grid item xs={3}>
								<ButtonCustom onClick={() => setDeleteFlag(false)} mt="0">
									No
								</ButtonCustom>
							</Grid>
							<Grid item xs={3}>
								<ButtonCustom
									//  onClick={deleteEducation}
									mt="0"
								>
									Yes
								</ButtonCustom>
							</Grid>
						</Grid>
					</Card>
				</CustomModal>
			)}
		</>
	);
};

export default Education;
