import { Avatar, Badge, Box, Card, useMediaQuery, useTheme } from "@mui/material";
import BasicDatePicker from "../../components/elements/DatePicker";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import titles from "../../data/titles.json";
import genders from "../../data/genders.json";
import state_dists from "../../data/state_dist.json";
import { useField } from "../../hooks/useField";

import { useEffect, useRef, useState } from "react";
import useOnboardingHelper from "../../hooks/useOnboardingHelper"
import useAdminHelper from "../../hooks/useAdminHelper"
import ProfileFormFooter from "../profile/ProfileFormFooter";
import { isEmail, isNumeric } from "validator";
import EditIcon from '@mui/icons-material/Edit';
import {
  isEmailValid,
  isPhoneNumberValid,
  isPincodeValid,
  isRequired,
} from "../../utils/validators";
import { useStore } from "../../stores";
import CustomModal from "../../components/elements/CustomModal";
import OtpView from "../auth/OtpView";
import { useFileUpload } from "use-file-upload";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { CustomSnackbar } from "../../components/elements/CustomSnackbar";

const HorizontalSpacer = () => <Spacer width={1} />;
const VerticalSpacer = () => <Spacer height={2} />;

const SetPersonalInfo = ({ steps, index, handleBack, setUserId }) => {
  let currentUser = useStore((state) => state.currentUser);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const navigate=useNavigate();
  const title = useField({ validate: isRequired });
  const fullName = useField({ validate: isRequired });
  const email = useField({});
  const mobileNum = useField({});
   const emailWhenUntouched = useRef();
  const phoneNumWhenUntouched = useRef();
  const [showSetProfileModal, setShowSetProfileModal] = useState(false);
  const [showEmailOtplink, setShowEmailOtpLink] = useState(false);
  const [showSmsOtplink, setShowSmsOtpLink] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [file, selectFile] = useFileUpload();
  const [profileImageurl, setProfileImageurl] = useState();

  const {saveEducationInfo,
    savePersonalInfo,
    getPersonalInfo,
   
   
  } = useOnboardingHelper();

  const { createUser } = useAdminHelper();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const query = new URLSearchParams(window.location.search);
  const sId = query.get("schoolId");
  var schoolsId=sId.split('/')
  var schoolId=schoolsId[0]
  console.log("schoolId",schoolId)
  var userId = schoolsId[1];
  console.log("userId",userId)
  const formErrCleanupValidation = () => {
    title.setShowError(true);
    fullName.setShowError(true);
  

    if (!email.value && !mobileNum.value) {
      email.setMeta({ error: "One of email or phone number is required" });
      mobileNum.setMeta({ error: "One of email or phone number is required" });
      email.setShowError(true);
      mobileNum.setShowError(true);
      return false;
    }
    if (email.value) {
      let err = isEmailValid({ value: email.value });
      if (err) {
        email.setMeta({ error: err });
        email.setShowError(true);
        return false;
      }
    }
    if (mobileNum.value) {
      let err = isPhoneNumberValid({ value: String(mobileNum.value) });
      if (err) {
        mobileNum.setMeta({
          error: err,
        });
        mobileNum.setShowError(true);
        return false;
      }
    }
  

    return true;
  };
  useEffect(() => {
    console.log(userId);
    console.log(schoolId)
    let fetchPersonalInfo = async (userId) => {
    
      let info = await getPersonalInfo(userId);
      if (info?.data?.success && info?.data?.data?.id) {
        setIsFirstTime(false);
       
       
        if (info.data.data["email"]) {
          email.setValue(info.data.data["email"]);
          emailWhenUntouched.current = info.data.data["email"];
        }
        fullName.setValue(info.data.data["fullName"]);
       
        if (info.data.data["phoneNumber"]) {
          let num = info.data.data["phoneNumber"].slice(3);
          mobileNum.setValue(num);
          phoneNumWhenUntouched.current = num;
        }
        

      } else if (info?.data?.success && info?.data?.data) {
        setIsFirstTime(true);
      
        if (info.data.data["email"]) {
          email.setValue(info.data.data["email"]);
          emailWhenUntouched.current = info.data.data["email"];
        }
        if (info.data.data["fullName"]) {
          fullName.setValue(info.data.data["fullName"]);
        }
        
        
        if (info.data.data["phoneNumber"]) {
          let num = info.data.data["phoneNumber"].slice(3);
          mobileNum.setValue(num);
          phoneNumWhenUntouched.current = num;
        }
      } else if (info?.data?.success) {
        setIsFirstTime(true);
      }
    };
   
      
      if (userId) {
       
      
        setUserId(userId);
        fetchPersonalInfo(userId);
      
    }
  }, [userId]);
  const emailModifyCheck = async () => {
   
    let trimmedEmail = email.value.trim();
    if (!trimmedEmail) return;
    email.setValue(trimmedEmail);
    if (!isEmail(trimmedEmail)) {
      email.setMeta({ error: isEmailValid({ value: trimmedEmail }) });
      return email.setShowError(true);
    }

    
  };
  const phoneNumModifyCheck = async () => {
   
    if (!mobileNum.value) return false;
    if (!isNumeric(mobileNum.value) && String(mobileNum.value).length !== 10) {
      mobileNum.setMeta({
        error: isPhoneNumberValid({ value: mobileNum.value }),
      });
      return mobileNum.setShowError(true);
    }
  
  };
  var userInfoVal = window.localStorage.getItem("current_user");
  var userInfo = JSON.parse(userInfoVal).state.currentUser;
  var userRoles=userInfo.userRole;
  const onSubmit = async () => {
    document.getElementById("special_email").blur();
    document.getElementById("special_phoneNum").blur();
    submitPersonalInfo();
   
   
  };
  var infoId=""
  const submitEducationInfo = async (fileUrl = "") => {
    let res = await saveEducationInfo(infoId, {
      "school": schoolId,
      "grade": "1",
      "section": "A",
     
      userId: fileUrl
    });
   
  };
  const submitPersonalInfo = async () => {
    console.log( mobileNum?.value)
    phoneNumModifyCheck()
    let localUserId = userId;
    let phoneNumber = mobileNum?.value ? ('+91' + mobileNum?.value) : '';
    console.log("phoneNumber",phoneNumber)
    if(phoneNumber.length===13){
      if(userRoles!=='SCHOOL_ADMIN' && !userId){
      let user = await createUser({
        fullName:fullName.value,
        email: email.value.toLowerCase(),
        phoneNumber: phoneNumber,
        userRole:"SCHOOL_ADMIN"
      });
      if (user.data.success) {
        localUserId = user.data.data.id;
        setUserId(user.data.data.id);
        submitEducationInfo(user.data.data.id);
        navigate("/coordinators?schoolId="+ schoolId)
      } else {
        console.log(user.message);
      }
      }
  else{
    let res = await savePersonalInfo({
      userId: localUserId,
      "city": "",
      country: "",
      "dob": "",
      district: "",
      "email": email.value.toLowerCase(),
      "fullName": fullName.value,
      gender: "",
      "pinCode": "",
      state: "",
      "taluk": "",
      title: "",
      address: "",
      rollNumber:"",
      "phoneNumber": phoneNumber,
      "userRole": "SCHOOL_ADMIN",
      profileImageurl: "",
    });
    if (res.data.success) {
     
        setCurrentUser({
          ...currentUser,
          phoneNumber: "+91" + mobileNum.value,
          email: email.value,
        });
      
        navigate("/coordinators?schoolId="+ schoolId)
    }}}
    else
    {
      setSnakeBarProps({ snackbarFlag: true, msz: "Enter a valid nummber", type: "error" })
    }
  };
  return (
    <>
      <Box>
        <FlexContainer al="flex-start">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<EditIcon style={{ fontSize: 12 }} />}
            backgroundColor="#82D2DA"
            className="avatar-badge"
          >
            <Avatar
              src={file?.source ? file?.source : profileImageurl}
              sx={{ width: 60, height: 60, margin: 0 }}
              onClick={() => selectFile({ accept: "image/*" })}
            />
          </Badge>
          <Box
            width={matches ? "300px" : "600px"}
            sx={{ paddingRight: "2rem" }}
          >
            <FlexContainer al="flex-start">
              <Input
                label="Title"
                select
                options={titles}
                width="100px"
                {...title.getInputProps()}
                errorText={title.showError && title.meta.error}
              
              /><span style={{color:"red", fontWeight:"bold"}}>*</span>
              <HorizontalSpacer />
              <Input
                label="Full Name"
                {...fullName.getInputProps()}
                errorText={fullName.showError && fullName.meta.error}
                
              /><span style={{color:"red", fontWeight:"bold"}}>*</span>
            </FlexContainer>
            <VerticalSpacer />
          
            <Input
              label="E-mail Address"
              {...email.getInputProps()}
              errorText={email.showError && email.meta.error}
              onBlur={emailModifyCheck}
              id="special_email"
              shrink={false}
              width="538px"
            /><span style={{color:"red", fontWeight:"bold"}}>*</span>

            <VerticalSpacer />
            <Input
              label="Your 10-digit mobile no. "
              {...mobileNum.getInputProps()}
              maxChar="10"
             
              errorText={mobileNum.showError && mobileNum.meta.error}
              onBlur={phoneNumModifyCheck}
              id="special_phoneNum"
              width="538px"
            /><span style={{color:"red", fontWeight:"bold"}}>*</span>
            <VerticalSpacer />
            
           
          
            <VerticalSpacer />
          </Box>
        </FlexContainer>
      </Box>
      <ProfileFormFooter
        steps={steps}
        index={index}
        handleNext={onSubmit}
        handleBack={handleBack}
      
      />
      <CustomModal
        open={showSetProfileModal}
        setOpen={setShowSetProfileModal}
        closeOnOutsideClick={false}
      >
        <Card
          sx={{
            px: 6,
            pt: 7,
            pb: 3,
            width: "45vw",
            position: "relative",
            margin: "auto",
            mt: "10vh",
          }}
        >
          {showEmailOtplink ? (
            <OtpView
              forAuth={false}
              email={email}
              onSuccess={() => {
                setShowSetProfileModal(false);
                setShowEmailOtpLink(false);
              }}
              revertChange={() => {
                email.setValue(emailWhenUntouched.current ? emailWhenUntouched.current : '');
                setShowSetProfileModal(false);
                setShowEmailOtpLink(false);
              }}
            />
          ) : showSmsOtplink ? (
            <OtpView
              forAuth={false}
              onSuccess={() => {
                setShowSetProfileModal(false);
                setShowSmsOtpLink(false);
              }}
              phoneNumber={mobileNum}
              revertChange={() => {
                mobileNum.setValue(phoneNumWhenUntouched.current ? phoneNumWhenUntouched.current : '');
                setShowSetProfileModal(false);
                setShowSmsOtpLink(false);
              }}
            />
          ) : null}
        </Card>
      </CustomModal>
      {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
    </>
  );
};

export default SetPersonalInfo;
