import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import ProfileFormFooter from "./ProfileFormFooter";
import useRoleHelper from "../../hooks/useRoleHelper";
import useUsersHelper from "../../hooks/useAdminHelper";
import { useField } from "../../hooks/useField";

const HorizontalSpacer = () => <Spacer width={1} />;
const VerticalSpacer = () => <Spacer height={2} />;

const AdminRole = ({ steps, index, handleNext, handleBack,userId }) => {
  const roleField = useField({});
  const [roles, setRoles] = useState([]);
  const { getRoles } = useRoleHelper();
  const { updateUser }  = useUsersHelper();
  useEffect(() => {
    let fetchRoleInfo = async () => {
      let info = await getRoles();
      console.log(info)
      if (info.data.success && info.data.data.length !== 0) {
        setRoles(info.data.data);
      }
    };
    fetchRoleInfo();
  }, []);

  const onSubmit = async () => {

    let res = await updateUser(userId,{userRole:roleField.value});
    if (res.data.success) {
      handleNext();
    }
  };
  return (
    <>
      <Box>
        <Box margin="auto" width="600px">
          <VerticalSpacer />
          <FlexContainer al="flex-start">
            <HorizontalSpacer />
            <Input
              label="Role"
              select
              options={roles.map((role) => ({
                value: role.roleTitle,
                label: role.roleTitle,
              }))}
              {...roleField.getInputProps()}
              errorText={roleField.showError && roleField.meta.error}
            />
          </FlexContainer>          
        </Box>
      </Box>
      <ProfileFormFooter
        steps={steps}
        index={index}
        handleNext={onSubmit}
        handleBack={handleBack}
      />
    </>
  );
};

export default AdminRole;
