import React, { useEffect, useMemo } from "react";
import MaterialTable from "material-table";
import { Link, Box, Modal, CircularProgress, Backdrop, Typography, ListItem, ListItemText } from "@mui/material";
import { Button, Grid } from "@mui/material";
import GlobalLayout from "../../components/layout/GlobalLayout";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import useUsersHelper from "../../hooks/useAdminHelper";
import { CustomSnackbar } from "../../components/elements/CustomSnackbar";
import AlertMessageModal from "../../components/attention/AlertMessageModal";
import { useNavigate, useParams } from "react-router-dom";
import InviteUser from "../profile/InviteUser";
import BulkUser from "../profile/BulkUser";
import { studentInfoObj } from "../../hooks/useFeatureHelper";
import ResetPassword from "../profile/ResetPassword";
import { Stack } from "@mui/system";
import ResetPasswordModal from "../profile/ResetPassword";
import { CsvBuilder } from "filefy";
import { environment } from "../../environments/environment";
import useSessionHelper from "../../hooks/useSessionHelper";
import useStudentHelper from "../../hooks/useStudentHelper";

const Classes = () => {

  const { inviteAll } = useUsersHelper();

  const navigate = useNavigate();

  const [currentRowId, setCurrentRowId] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadFileUrl] = React.useState("");
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [importFileFlag, setImportFileFlag] = useState(false);
  const [delMessage, setDelMessage] = React.useState(false);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [modalLoaderFlag, setModalLoaderFlag] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [syncData, setSyncData] = useState({});
  const { getUsersGrade, deleteUser, inviteOneUser } = useUsersHelper();
  const { sync, getSync, getReports } = useSessionHelper();
  const {getStudents} = useStudentHelper();
  const query = new URLSearchParams(window.location.search);
  const schoolId = query.get("schoolId");
  let curentUser = JSON.parse(localStorage.current_user,);

  let token_User=curentUser.state.currentUser.access_token;
  const { data: usersData, isLoading: userLoader } = useQuery(
		[`UsersData`, importFileFlag],
		() => getStudents(schoolId),
		{ enabled: true, retry: false }
	);
  const { data: ReportData, isLoading: reportLoader } = useQuery(
    [`ReportData`, schoolId],
    () => getReports({ schoolId }),
    { enabled: !!schoolId, retry: false })

  const [report, setReport] = useState({});
  var userInfoVal = window.localStorage.getItem("current_user");
  var userInfo = JSON.parse(userInfoVal).state.currentUser;
  var userRoles = userInfo.userRole;
  const handleClassHallticketClick = (classId) => {
    window.open(`https://reports.liveolympiad.org/hall_tickets.php?center=${schoolId}&class=${classId}&env=${environment.env}&token=${token_User}`);
  }
  const classData = useMemo(() => {
    let classJson = {}
    console.log(usersData?.data?.data)
    usersData?.data?.data?.forEach((student) => {
      if(!(student?.grade in classJson)){
        classJson[student?.grade] = 0;
      }
      classJson[student?.grade]++;
    })
    let classArr = []
    Object.keys(classJson).forEach((key) => classArr.push({class:key, count:classJson[key]}))
    console.log(classJson)
    return classArr.sort((a,b) => a.class.split("-")[1] - b.class.split("-")[1])
  },[usersData])

  useEffect(() => {
    let reportMap = {}
    ReportData?.data?.data?.batchReports?.forEach((batchReport) => {
      if (!(batchReport.group in reportMap)) {
        reportMap[batchReport.group] = {}
      }
      if (!(batchReport.course in reportMap)) {
        reportMap[batchReport.group][batchReport.course] = batchReport?.studentReports.length
      }
    })
    setReport(reportMap)
  }, [ReportData])

  const fetchSyncData = async () => {
    let classTestSyncData = {};
    let resp = await fetch()
    if (resp) {
      Object.keys(resp).forEach((key) => {
        classTestSyncData[key] = resp[key]
      })
    }
    return classTestSyncData;
  }

  let tableData = usersData?.data?.data?.grades;


  const fetch = async () => {
    let resp = await getSync(schoolId)
    return resp?.data?.data
  }

  useEffect(() => {
    if (environment.env != "live" && schoolId) {
      fetchSyncData().then((resp) => {
        console.log(resp)
        setSyncData(resp)
      });
    }
  }, [])

  let columns = [{ title: 'Class', field: 'class' }]
  let subs = new Set();
  console.log("tableeeeeeeeeeeeeeeeee",tableData)
  tableData?.forEach((grade) => {
    console.log(grade)
    Object.keys(grade["subs"]).forEach((sub) => {
      subs.add(sub);
    })
  })
  subs?.forEach((subject) => {
    columns.push({
      title: subject, field: `subs.${subject}`
      , render: rowData => {
        return (
          <Grid container>
            <Grid item>
              <Button>
                <Link style={{ marginRight: '5px' }} href={`/reports?schoolId=${schoolId}&grade=${rowData.id}&subject=${subject}`}>{rowData.id in report && subject in report[rowData.id] ? report[rowData.id][subject] : 0}/{rowData.subs[subject] ? rowData.subs[subject] : 0}</Link>
              </Button>
            </Grid>
          </Grid>
        )
      }
    })
  })

  columns.push({
    title: 'Total Students', field: 'count',
    render: rowData => {
      return (
       
        <Grid container>
          
          <Grid item>
            <Button>
              <Link style={{ marginRight: '5px' }} href={`/students?schoolId=${schoolId}&grade=${rowData.id}`}>{rowData.count}</Link>
            </Button>
            <Button>
              <Link style={{ marginRight: '5px' }} onClick={() => handleClassHallticketClick(rowData['class'])}>Hall Ticket</Link>
            </Button>
          </Grid>
        </Grid>

      )
    }
  })
  columns.push({
    title: 'Actions ', field: 'status',
    render: rowData => {
      return (
        <Grid container>
          <Grid item>
            <Button onClick={() => inviteAll(schoolId, rowData.class)}>
              <Link style={{ marginRight: '20px' }} href='#' >Invite</Link>
            </Button>
            <Button onClick={() => handleCertificateClickByGrade(rowData.class)}>
              <Link style={{ marginRight: '20px' }} href='#' >Certificates</Link>
            </Button>
            {environment.env !== "live" &&
              <>
                {syncData[rowData.id]?.nativeCount > 0 && <Button onClick={() => sync(rowData.id, schoolId)}>
                  <Link style={{ marginRight: '20px' }} >Sync</Link>
                </Button>}
              </>}

          </Grid>
        </Grid>

      )
    }
  })
  // columns.push({
  //   title: 'Sync Data ', field: 'status',
  //   render: rowData => {
  //     return (
  //       <Grid container>
  //         <Grid item>
  //           {environment.env != "live" &&
  //             <>
  //               <Typography>
  //                 {syncData[rowData.id] && Object.values(syncData[rowData.id]).join(",")}
  //               </Typography>
  //             </>}

  //         </Grid>
  //       </Grid>

  //     )
  //   }
  // })
  const handleSchoolReportsClick = () => {
    let curentUser = JSON.parse(localStorage.current_user);
    if ((curentUser?.state?.currentUser?.access_token)) {
      window.open(`https://reports.liveolympiad.org/intraschool.php?center=${schoolId}&noranks&token=${curentUser?.state?.currentUser?.access_token}`);
    }
  }
  const handleCertificateClick = () => {
    let curentUser = JSON.parse(localStorage.current_user);
    if ((curentUser?.state?.currentUser?.access_token)) {
      window.open(`https://reports.liveolympiad.org/certificates.php?center=${schoolId}&env=${environment.env}&token=${curentUser?.state?.currentUser?.access_token}`);
    }
  };

  const handleCertificateClickByGrade = (grade) => {
    let curentUser = JSON.parse(localStorage.current_user);
    if ((curentUser?.state?.currentUser?.access_token)) {
      window.open(`https://reports.liveolympiad.org/certificates.php?center=${schoolId}&env=${environment.env}&token=${curentUser?.state?.currentUser?.access_token}&grade=${grade}`);
    }
  }
  return (
    <GlobalLayout loader={userLoader}>
      <Grid container m={2} pr={3} style={{ marginTop: 100 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={6} lg={6}>
            <Typography sx={{ fontFamily: "GraphikMedium.oft", fontSize: '12px', fontWeight: 600, marginLeft: "5px" }}>{schoolName}</Typography>
          </Grid>
          {/* <Grid item xs={6} lg={6}>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
            >
              <Button variant="contained" style={{ borderRadius: '50px', marginRight: '5px' }} onClick={() => handleSchoolReportsClick()}>Reports</Button>
            </Grid>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
            >
              <Button variant="contained" style={{ borderRadius: '50px', marginRight: '5px' }} onClick={() => handleCertificateClick()}>Certificates</Button>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <MaterialTable
            columns={columns}
            totalCount={classData?.length}
            data={classData}
            title="Classes"
            options={{
              pageSize: 100,
              headerStyle: {
                backgroundColor: '#82D2DA'
              }
            }}
          />
        </Grid>
      </Grid>
      {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
    </GlobalLayout>
  );
};

export default Classes;
