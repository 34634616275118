import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, useTheme, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";


export const ProfileWrapper = (props) => {
    const { pageArr, infoArr, expandedIndex, setExpandedIndex, setCurrentIndex, parent } = props
    // console.log('props :>> ', props);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpandedIndex(newExpanded ? panel : false);
        setCurrentIndex(panel.split("_")[1]);
    };
    return <>
        {
            pageArr.length == 0 ? props.children
                : <>
                    {
                        pageArr.map((option, index) => {
                            return <Accordion expanded={expandedIndex == 'panel_' + index} onChange={handleChange('panel_' + index)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        "& .MuiAccordionSummary-contentGutters": {
                                            flexDirection: "column"
                                        },
                                    }}
                                >
                                    {
                                        infoArr[index] ? <>
                                            <Box width={1}><Typography>{infoArr[index]['info1']}</Typography></Box>
                                            <Box width={1}><Typography>{infoArr[index]['info2']}</Typography></Box>
                                            <Box width={1}><Typography>{infoArr[index]['info3']}</Typography></Box>
                                        </>
                                            : <Box width={1}><Typography>Add {parent}</Typography></Box>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    {props.children}
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                </>
        }
    </>
}
