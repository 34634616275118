import MaterialTable from "material-table";
import GlobalLayout from "../../components/layout/GlobalLayout"
import { Button, Grid } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const Users = () => {
    const navigate = useNavigate();
    
    return(
        <GlobalLayout>
            <Grid container m={2} pr={3} style={{ marginTop: 100 }}>
             <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center">
                <Button onClick={() => { navigate('/user')}} 
                        style={{ borderRadius: '50px', marginRight: '20px', 
                        color: "black", textDecoration: "none" }} 
                        variant='contained'>Create New Users</Button>
                </Grid>
                
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    { }
                    <MaterialTable
                        columns={[
                            { title: 'User Name', field: 'username' },
                            { title: 'User Id', field: 'userId' },
                            { title: 'User Password', field: 'userpass' },
                            { 
                                title: 'Actions', field: 'actions',
                                render: rowData =>{
                                    return(
                                      <Grid container>
                                        <Grid item>
                                            <Button>
                                            <Link style={{ marginLeft: '-50px', marginBottom: '-25px', color: "black", textDecoration: "none" }}
                                                    href={'#'} onClick={() => {""}}><DeleteIcon /></Link>
                                            </Button>
                                            <Button>
                                            <Link style={{ marginRight: '-10px', marginTop: '-22px', color:"black", textDecoration: "none" }} 
                                                    onClick={() => {""}}><EditIcon /></Link>
                                            </Button>
                                            
                                        </Grid>
                                        </Grid>
                                    )
                                }
                            },
                        ]}
                        totalCount={true}
                        title="Users"
                        options={{
                            pageSize:100,
                            headerStyle:{
                                background:'#82D2DA'
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </GlobalLayout>
    )
}
export default Users;