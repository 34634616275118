import { Button } from "@mui/material";
import { styled } from "@mui/system";

const ButtonWrapper = styled(Button)(({ theme, ...props }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: props.violet ? theme.palette.violet.main : "auto",
    borderRadius: props.borderRadius ? props.borderRadius : "50px",
    lineHeight: 1,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: props.violet ? theme.palette.violet.dark : "auto",
    },
    disabled: props.disabled,
  };
});
const ButtonCustom = ({ children, maxWidth, margin, marginBottom, ...props }) => {
  return (
    <ButtonWrapper
      sx={{
        maxWidth: maxWidth ? maxWidth : "min-content",
        margin: margin ? margin : "auto",
        boxShadow: 5,
        fontWeight: 650,

      }}
      variant="contained"
      {...props}
    >
      {children}
    </ButtonWrapper>
  );
};

export default ButtonCustom;
