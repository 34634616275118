import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
// import "./DatePicker.css";

export default function BasicDatePicker({
  label,
  width = "100%",
  value,
  setValue,
  ...props
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width,
        "& button:focus": {
          outline: "none",
        },
        "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
          { borderColor: theme.palette.primary.main },
        "& .MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
          color: "rgba(0, 0, 0, 0.38)",
        },
        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              id="outlined-name"
              sx={{
                outline: "none",
                padding: 0,
                "& input": {
                  padding: "15px 20px",
                },
                width: width,
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: 4,
                  borderColor: theme.palette.primary.main,
                },
                "& .MuiFormLabel-root-MuiInputLabel-root.Mui-error":
                  {
                    color: "rgba(0, 0, 0, 0.6)",
                  },
              }}
              InputLabelProps={{ style: { color: "rgba(0, 0, 0, 0.6)" } }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
