import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonCustom from "../../components/elements/ButtonCustom";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useField } from "../../hooks/useField";
import { isValidEmailOrMob } from "../../utils/validators";
import { isEmail } from "validator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { backIconStyles } from "../../theme/customStyles";

const ForgotPass = () => {
  const navigate = useNavigate();
  const emailOrMob = useField({ validate: isValidEmailOrMob });

  const [linkSent, setLinkSent] = useState(false);

  const { triggerPasswordReset } = useAuthHelper();

  const onEmailOrMobSubmit = async () => {
    emailOrMob.setShowError(true);
    if (emailOrMob.isValid) {
      let body = {
        "email" : isEmail(emailOrMob.value) ? emailOrMob.value.toLowerCase() : "",
        "phoneNumber" : isEmail(emailOrMob.value) ? "" : "+91" + emailOrMob.value
      }
      let res = await triggerPasswordReset(body);
      if (res?.data?.success) {
        setLinkSent(true);
      }
    }
  };

  if (!linkSent)
    return (
      <FlexContainer direction="column">
        <ArrowBackIcon sx={backIconStyles} onClick={() => navigate(-1)} />
        <Typography align="center" sx={{ fontSize: "1.3rem" }}>
          Password Assistance
        </Typography>
        <Spacer height={1} />
        <Typography align="center" paddingX={"20px"}>
          Please enter your registered mobile number or email address to get
          password reset link
        </Typography>
        <Spacer />
        <Input
          label="Phone or Email"
          {...emailOrMob.getInputProps()}
          errorText={emailOrMob.showError && emailOrMob.meta.error}
        />
        <Spacer />
        <ButtonCustom onClick={onEmailOrMobSubmit}>
          <Box>Send</Box>
        </ButtonCustom>
        <Spacer />
      </FlexContainer>
    );
  if (linkSent) {
    return (
      <FlexContainer direction="column">
        <Typography sx={{ fontSize: "1.3rem" }}>Password Assistance</Typography>
        <Spacer />
        <Typography align="center" paddingX={"20px"}>
          Password reset link sent to you.
        </Typography>
        <Spacer />
        <Typography align="center" paddingX={"20px"}>
          Not received yet?
        </Typography>
        <Spacer />
        <Typography
          align="center"
          style={{ color: "#005B64", cursor: "pointer", fontWeight: "bold" }}
          paddingX={"20px"}
          onClick={onEmailOrMobSubmit}
        >
          Resend
        </Typography>
        <Spacer height={5} />
      </FlexContainer>
    );
  }
};

export default ForgotPass;
