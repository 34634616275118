import React, { useEffect } from "react";
import MaterialTable from "material-table";
import {
	Link,
	Box,
	Modal,
	CircularProgress,
	Backdrop,
	Typography,
	IconButton,
} from "@mui/material";
import { environment } from "../../environments/environment";

import { Button, Grid } from "@mui/material";
import GlobalLayout from "../../components/layout/GlobalLayout";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import useUsersHelper from "../../hooks/useAdminHelper";
import { CustomSnackbar } from "../../components/elements/CustomSnackbar";
import AlertMessageModal from "../../components/attention/AlertMessageModal";
import { useNavigate, useParams } from "react-router-dom";
import InviteUser from "../profile/InviteUser";
import BulkUser from "../profile/BulkUser";
import { studentInfoObj } from "../../hooks/useFeatureHelper";
import ResetPassword from "../profile/ResetPassword";
import { fontFamily, Stack } from "@mui/system";
import ResetPasswordModal from "../profile/ResetPassword";
import { CsvBuilder } from "filefy";
import jwt_decode from "jwt-decode";
import useStudentHelper from "../../hooks/useStudentHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
const Students = () => {
	const navigate = useNavigate();

	const [currentRowId, setCurrentRowId] = React.useState(null);
	const [loading, setLoading] = useState(false);
	const [uploadFileUrl] = React.useState("");
	const [snakeBarProps, setSnakeBarProps] = useState({});
	const [importFileFlag, setImportFileFlag] = useState(false);
	const [delMessage, setDelMessage] = React.useState(false);
	const [tableArr, setTableArr] = React.useState([]);
	const [countPerPage, setCountPerPage] = useState(10);
	const [tableTotalCount, setTableTotalCount] = useState(1);
	const [page, setPage] = useState(1);
	const [modalLoaderFlag, setModalLoaderFlag] = useState(false);
	const [resetPassword, setResetPassword] = useState(false);
	const [schoolName, setSchoolName] = useState("");
	const { getUsers, deleteUser, inviteOneUser } = useUsersHelper();
	const { getStudents, deleteStudent } = useStudentHelper();
	const query = new URLSearchParams(window.location.search);
	const schoolId = query.get("schoolId");
	const grade = query.get("grade");
	const { data: usersData, isLoading: userLoader } = useQuery(
		[`UsersData`, importFileFlag],
		() => getStudents(schoolId),
		{ enabled: true, retry: false }
	);
	useEffect(() => {
		if (usersData) {
			usersData?.data?.data?.students?.data?.map((item) => {
				var s = new Date(item.createdOn).toLocaleDateString("en-US");
				item.createdOn = s;
			});
			setTableArr(usersData?.data?.data);
			// setSchoolName(usersData?.data?.data?.students?.data[0]?.schoolName);
			setTableTotalCount(usersData?.data?.data?.length);
		}
	}, [usersData, tableArr]);

	const deleteRow = () => {
		deleteMutate(currentRowId.userId);
		deleteStudent(currentRowId?._id)
	};
	// useEffect(() => {
	// 	getStudents(schoolId).then((res) => {
	// 		setSchoolName(res?.data?.data?.students?.data[0]?.schoolName);
	// 		setTableArr(res?.data?.data?.data);
	// 		setTableTotalCount(res?.data?.data?.totalElements);
	// 	});
	// }, [schoolId]);

	const { mutate: deleteMutate } = useMutation(deleteUser, {
		onSuccess: (data, variables, context) =>
			onSuccessUserDelete(data, variables, context),
		onError: (data, variables, context) =>
			onErrorUserDelete(data, variables, context),
	});

	const onSuccessUserDelete = (data, variables, context) => {
		if (data) {
			setSnakeBarProps({
				snackbarFlag: true,
				msz: data.data.message,
				type: "success",
			});
			setImportFileFlag(true);
			setDelMessage(false);
		}
	};

	const onErrorUserDelete = (error, variables, context) => {
		error &&
			setSnakeBarProps({
				snackbarFlag: true,
				msz: error.message,
				type: "error",
			});
	};

	const downloadSample = () => {
		importSampleMutate({ fileUrl: uploadFileUrl });
	};

	const getSchoolReport = () => {
		let curentUser = JSON.parse(localStorage.current_user);
		if(curentUser?.state?.currentUser?.access_token){
			window.open(`https://reports.liveolympiad.org/lo_reg_report.php?center=${schoolId}&env=${environment.env}&token=${curentUser?.state?.currentUser?.access_token}`);
		}
	  }

	const { mutate: importSampleMutate } = useMutation(downloadSample, {
		onSuccess: (data, variables, context) =>
			onSuccessImportSample(data, variables, context),
		onError: (data, variables, context) =>
			onErrorImportSample(data, variables, context),
	});

	const onSuccessImportSample = (data, variables, context) => {
		if (data) {
			setSnakeBarProps({
				snackbarFlag: true,
				msz: data.data.message,
				type: "success",
			});
			setImportFileFlag(true);
		}
	};

	const onErrorImportSample = (error, variables, context) => {
		error &&
			setSnakeBarProps({
				snackbarFlag: true,
				msz: error.message,
				type: "error",
			});
	};

	const inviteUser = async (email, phoneNumber) => {
		if (email || phoneNumber) {
			let user = await inviteOneUser({
				email: email.toLowerCase(),
				phoneNumber: phoneNumber,
				sendInvite: true,
			});
			if (!user.data.success) {
				console.log(user.message);
			}
		}
	};
	var userInfoVal = window.localStorage.getItem("current_user");
	var userInfo = JSON.parse(userInfoVal).state.currentUser;
	var userRoles = userInfo.userRole;

	return (
		<GlobalLayout loader={userLoader || modalLoaderFlag}>
			<Grid container m={2} pr={3} style={{ marginTop: 100 }}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={6} lg={6}>
						<Typography
							sx={{
								fontFamily: "GraphikMedium.oft",
								fontSize: "12px",
								fontWeight: 600,
								marginLeft: "5px",
							}}
						>
							{schoolName}
						</Typography>
					</Grid>
					<Grid item xs={6} lg={6}>
						<Grid
							container
							direction="row"
							justifyContent="end"
							alignItems="center"
						>
							<Button style={{
									borderRadius: "50px",
									marginRight: "20px",
									textDecoration: "none",
								}}
								variant="contained"onClick={() => getSchoolReport()}>
								School Reports
							</Button>
							<Button
								onClick={() => {
									if (userRoles !== "STUDENT")
										navigate(`/profile/createStudent?school=${schoolId}`);
								}}
								style={{
									borderRadius: "50px",
									marginRight: "20px",
									color: userRoles !== "STUDENT" ? "" : "grey",
									textDecoration: "none",
								}}
								variant="contained"
							>
								Add New Student
							</Button>
							{/* <InviteUser setModalLoaderFlag={setModalLoaderFlag} /> */}
							{userRoles !== "SCHOOL_ADMIN" && (
								<BulkUser setLoading={setLoading} />
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ marginTop: 20 }}>
					<MaterialTable
						columns={[
							{ title: "Full Name", field: "fullName" },
							{ title: "Phone Number", field: "phoneNumber" },
							{ title: "Grade", field: "grade" },
							{ title: "Created On", field: "createdAt", render: (rowData) => (<>{rowData?.createdAt?.split("T")[0]} {rowData?.createdAt?.split("T")[1].split(".")[0]}</>) },
							// { title: "Is Active", field: "active" },
							{ title: "Password", field: "securityPin" },
							{ title: "Join Type", field: "joinType" },
							// 	render: (rowData) => {
							// 		return (
							// 			<>
							// 				{rowData.schoolList &&
							// 					rowData.schoolList[0].joinType !== "SELF" &&
							// 					!rowData.active && (
							// 						<Box>
							// 							<Link
							// 								href="#"
							// 								onClick={() => {
							// 									inviteUser(rowData.email, rowData.phoneNumber);
							// 								}}
							// 							>
							// 								Resend
							// 							</Link>
							// 						</Box>
							// 					)}
							// 				{rowData.active && rowData.schoolList && (
							// 					<Box>{rowData.schoolList[0].joinType}</Box>
							// 				)}
							// 			</>
							// 		);
							// 	},
							// },
							{
								title: "Actions ",
								field: "status",
								render: (rowData) => {
									return (
										<Stack spacing={1} direction="row">
												<IconButton
													sx={{
														color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
														textDecoration: "none",
													}}
													onClick={() => {navigate(userRoles !== "SCHOOL_ADMIN"
													? "/profile/editStudent_" +
													  rowData._id +
													  "?school=" +
													  schoolId
													: " ")}}
												>
													<EditIcon/>
												</IconButton>
												<IconButton
													sx={{
														color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
														textDecoration: "none",
													}}
													onClick={() => {
														if (userRoles !== "SCHOOL_ADMIN") {
															setDelMessage(true);
															setCurrentRowId(rowData);
															setImportFileFlag(false);
														}
													}}
												>
													<DeleteIcon/>
												</IconButton>
												<IconButton
													sx={{
														color: userRoles !== "SCHOOL_ADMIN" ? "" : "grey",
														textDecoration: "none",
													}}
													onClick={() => {
														if (userRoles !== "SCHOOL_ADMIN") {
															setResetPassword(true);
															setCurrentRowId(rowData);
														}
													}}
												>
													<ManageAccountsIcon/>
												</IconButton>
										</Stack>
									);
								},
							},
						]}
						totalCount={tableTotalCount}
						data={tableArr}
						// page={page - 1}
						// onChangePage={(page) => { setPage(page + 1); }}
						onChangeRowsPerPage={(page) => {
							setCountPerPage(page);
						}}
						title={schoolName}
						options={{
							pageSize: 100,
							exportButton: true,
							headerStyle: {
								backgroundColor: "#82D2DA",
							},
							exportCsv: (columns, data) => {
								const columnTitles = Object.keys(tableArr[0]);

								const csvData = tableArr.map((rowData) =>
									columnTitles.map((columnDef) => `${rowData[columnDef]}`)
								);

								const builder = new CsvBuilder(`data.csv`)
									.setColumns(columnTitles)
									.addRows(csvData)
									.exportFile();

								return builder;
							},
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					{delMessage && (
						<AlertMessageModal
							setDelMessage={setDelMessage}
							alertMessage="Do you want to remove the user?"
							okbtnFlag="true"
							cancelBtnFlag="true"
							onClose={() => {
								setDelMessage(false);
							}}
							onClick={() => {
								deleteRow();
							}}
						/>
					)}
					{
						<ResetPasswordModal
							userId={currentRowId?.userId}
							studentId={currentRowId?._id}
							open={resetPassword}
							setOpen={setResetPassword}
						/>
					}
				</Grid>
			</Grid>
			{Object.keys(snakeBarProps).length > 0 && (
				<CustomSnackbar
					{...snakeBarProps}
					setSnakeBarProps={setSnakeBarProps}
				/>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</GlobalLayout>
	);
};

export default Students;
