import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";
import { convertDatainString } from "./useFeatureHelper";

const useSchoolHelper = () => {
	const getAllSchools = async () => {
		const endpoint = `${environment.schoolsUrl}/search?type=${environment.institutionDomain}&type=Tech`;
		return await axiosInstance(false).get(endpoint);
	};
	const getSchoolBySales = async (id) => {
		const endpoint = `${environment.schoolsUrl}/search?salesChannelId=${id}`;
		return await axiosInstance(false).get(endpoint);
	};
	const getSchoolByPincode = async (pinCode) => {
		const endpoint = `${environment.schoolsUrl}/search?pincode=${pinCode}`;
		return await axiosInstance(false).get(endpoint);
	};
	const getSchoolInfo = async (schoolId) => {
		const endpoint = `${environment.schoolsUrl}/${schoolId}`;
		return await axiosInstance(false).get(endpoint);
	};
	const addSchool = async (body) => {
		const endpoint = `${environment.schoolsUrl}`;
		return await axiosInstance().post(endpoint, body);
	};
	const updateSchool = async (body) => {
		const endpoint = `${environment.schoolsUrl}`;
		return await axiosInstance().post(endpoint, body);
	};
	const deleteSchool = async (schoolId) => {
		const endpoint = `${environment.schoolsUrl}/${schoolId}`;
		return await axiosInstance().delete(endpoint);
	};
	const getMedal = async () => {
		const endpoint = environment.sessionUrl + Endpoints.GET_MEDAL;
		return await axiosInstance(false).get(endpoint);
	};
	const addAdmin = async (body) => {
		const endpoint = environment.userBaseUrl + "admin";
		return await axiosInstance().post(endpoint, body);
	};
	return {
		getAllSchools,
		getSchoolBySales,
		addSchool,
		updateSchool,
		deleteSchool,
		getSchoolByPincode,
		getSchoolInfo,
		getMedal,
		addAdmin,
	};
};

export default useSchoolHelper;
