export const environment = {
  production: false,
  userBaseUrl: "https://users.liveolympiad.app/", 
  paymentUrl: "https://payments.liveolympiad.app/", 
  domain: "digishaala.com",
  studentUrl: "",  
  lcmsUrl: "",
  cookieDomain: ".digishaala.com",
  facebook_app_id: "", 
  google_client_id: "",
  reports: "https://reports.liveolympiad.app",
  contentUrl:"https://content-uat.liveolympiad.org",
  sessionUrl:'https://sessions-uat.digishaala.com/',
  env:'live',
  schoolsUrl:"https://schools.liveolympiad.app",
  studentsUrl:"https://students.liveolympiad.app",
  institutionDomain:'K12'
};
