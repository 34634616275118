import { Navigate } from "react-router-dom";
import ResetPass from "../features/auth/ResetPass";
import AuthScreen from "../features/auth/AuthScreen";
import Dashboard from "../features/dashboard/Dashboard";
import Home from "../features/dashboard/Home";
import SetUserProfile from "../features/profile/SetUserProfile";
import Students from "../features/students";
import Admins from "../features/admins";
import Roles from "../features/roles";
import Schools from "../features/schools";
import EditRole from "../features/roles/EditRole";
import PrivacyPolicy from "../features/PrivacyPolicy";
import TOC from "../features/TOC";
import School from "../features/schools/School";
import SetSchool from "../features/schools/SetSchool";
import Classes from "../features/classes";
import Coordinators from "../features/coordinators";
import SetCoordinator from "../features/coordinators/CoordinatorForm";
import Reports from "../features/reports";
import Report from "../features/reports/report";
import NoteBook from "../features/Notebook/index"
import Users from "../features/Users";
import User from "../features/Users/user";
export const routes = [
  { path: "/", element: <Navigate to="/login/emailpass" replace /> },
  {
    path: "/signup/:routeType",
    element: <AuthScreen />,
  },
  {
    path: "/login/:routeType",
    element: <AuthScreen />,
  },
  {
    path: "/forgotpass",
    element: <AuthScreen />,
  },
  {
    path: "/set-password",
    element: <ResetPass cleanUser={true} />,
  },  
  {
    path: "/dashboard",
    element: <Dashboard />,
  }, 
  {
    path: "/home",
    element: <Home />,
  },   
  {
    path: "/students",
    element: <Students />,
  }, 
  {
    path: "/coordinators",
    element: <Coordinators />,
  },    
  {
    path: "/admins",
    element: <Admins />,
  },   
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/roles",
    element: <Roles />,
  },
  {
    path:"/roles/:roleId",
    element: <EditRole/>
  },
  {
    path: "/schools",
    element: <Schools />,
  },    
  {
    path: "/classes",
    element: <Classes />,
  },  
  {
    path: "/school",
    element: <SetSchool />,
  },  
  {
    path: "/CoordinatorForm/",
    element: <SetCoordinator/>,
  },
  {
    path: "/CoordinatorForm/:userId",
    element: <SetCoordinator/>,
  },
  {
    path: "/profile/:paramsUserId",
    element: <SetUserProfile />,
  },   
  {
    path: "/reset-password",
    element: <ResetPass cleanUser={false} />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy/>
  },
  {
    path: "toc",
    element: <TOC/>
  },
  {
    path: "*",
    element: <Navigate to="/login/phone" replace />,
  },
  {
    path:"/notebook",
    element:<NoteBook/>
  },
  {
    path:"/Users",
    element:<Users/>
  },
  {
    path:"/user",
    element:<User/>
  },
];
