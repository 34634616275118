import {
	Avatar,
	Badge,
	Box,
	Card,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import BasicDatePicker from "../../components/elements/DatePicker";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import titles from "../../data/titles.json";
import genders from "../../data/genders.json";
import state_dists from "../../data/state_dist.json";
import { useField } from "../../hooks/useField";

import { useEffect, useRef, useState } from "react";
import useOnboardingHelper from "../../hooks/useOnboardingHelper";
import useAdminHelper from "../../hooks/useAdminHelper";
import ProfileFormFooter from "./ProfileFormFooter";
import { isEmail, isNumeric } from "validator";
import EditIcon from "@mui/icons-material/Edit";
import {
	isEmailValid,
	isPhoneNumberValid,
	isPincodeValid,
	isRequired,
} from "../../utils/validators";
import { useStore } from "../../stores";
import CustomModal from "../../components/elements/CustomModal";
import OtpView from "../auth/OtpView";
import { useFileUpload } from "use-file-upload";
import { useParams } from "react-router-dom";
import useStudentHelper from "../../hooks/useStudentHelper";

const HorizontalSpacer = () => <Spacer width={1} />;
const VerticalSpacer = () => <Spacer height={2} />;

const PersonalInfo = ({
	steps,
	index,
	handleNext,
	handleBack,
	setStudentId,
	studentId,
	setPincode,
}) => {
	let currentUser = useStore((state) => state.currentUser);
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const [userId, setUserId] = useState("")
	const title = useField({});
	const fullName = useField({ validate: isRequired });
	const email = useField({});
	const mobileNum = useField({});
	const state = useField({});
	const district = useField({});
	const [dob, setDob] = useState("");
	const gender = useField({});
	const talukOrTehsil = useField({});
	const cityOrVillage = useField({});
	const pinCode = useField({});
	const address = useField({});
	const rollNumber = useField({});
	const [isFirstTime, setIsFirstTime] = useState(true);
	const emailWhenUntouched = useRef();
	const phoneNumWhenUntouched = useRef();
	const [showSetProfileModal, setShowSetProfileModal] = useState(false);
	const [showEmailOtplink, setShowEmailOtpLink] = useState(false);
	const [showSmsOtplink, setShowSmsOtpLink] = useState(false);
	const [otpInProgress, setOtpInProgress] = useState(false);
	const [file, selectFile] = useFileUpload();
	const [profileImageurl, setProfileImageurl] = useState();
	const [checkpassed, setCheckpassed] = useState(false);
	const { generateOtpModifyEmailOrPhone, uploadFile } = useOnboardingHelper();

	const { getStudent, saveStudent } = useStudentHelper();
	const { createUser } = useAdminHelper();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	let { paramsUserId } = useParams();

	useEffect(() => {
		console.log(paramsUserId);
		let fetchPersonalInfo = async (userId) => {
			if (paramsUserId.startsWith("create") && !userId) return;
			let info = await getStudent(userId);
			if (info?.data?.success && info?.data?.data) {
				console.log("here")
				setUserId(info.data.data['userId'])
				if (info.data.data["profileImageurl"]) {
					setProfileImageurl(info.data.data["profileImageurl"]);
				}
				if (info.data.data?.address?.city) {
					cityOrVillage.setValue(info.data.data?.address?.city);
				}
				if (info.data.data["dob"]) {
					setDob(info.data.data["dob"]);
				}
				if (info.data.data?.address?.state) {
					state.setValue(info.data.data?.address["state"]);
				}
				if (info.data.data?.address?.district) {
					district.setValue(info.data.data?.address?.district);
				}
				if (info.data.data["email"]) {
					email.setValue(info.data.data["email"]);
					emailWhenUntouched.current = info.data.data["email"];
				}
				if (info.data.data["fullName"]) {
					console.log(info.data.data["fullName"])
					fullName.setValue(info.data.data["fullName"]);
				}
				if (info.data.data["title"]) {
					title.setValue(info.data.data["title"]);
				}
				if (info.data.data["gender"]) {
					gender.setValue(info.data.data["gender"]);
				}
				if (info.data.data?.address?.pincode) {
					pinCode.setValue(info.data.data?.address?.pincode);
					setPincode(info.data.data?.address?.pincode);
				}
				if (info.data.data?.address?.taluk) {
					talukOrTehsil.setValue(info.data.data?.address?.taluk);
				}
				if (info.data.data?.address?.address) {
					address.setValue(info.data.data?.address?.address);
				}
				if (info.data.data["rollNumber"]) {
					rollNumber.setValue(info.data.data["rollNumber"]);
				}
				if (info.data.data["phoneNumber"]) {
					console.log(info.data.data)
					mobileNum.setValue(info.data.data["phoneNumber"]);
				}
			}
		};
		if (paramsUserId.startsWith("edit") || studentId != null) {
			console.log(paramsUserId);
			const params = paramsUserId.split("_");
			if (params.length > 1) {
				setStudentId(params[1]);
				fetchPersonalInfo(params[1]);
			} else if (studentId != null) {
				fetchPersonalInfo(studentId);
			} else {
				setStudentId(paramsUserId);
				fetchPersonalInfo(paramsUserId);
			}
		}
	}, [studentId]);

	const formErrCleanupValidation = () => {
		title.setShowError(true);
		fullName.setShowError(true);
		// state.setShowError(true);
		// district.setShowError(true);
		// gender.setShowError(true);
		// talukOrTehsil.setShowError(true);
		// cityOrVillage.setShowError(true);
		// pinCode.setShowError(true);
		// address.setShowError(true);
		if(!studentId){
			if (!email.value && !mobileNum.value) {
				email.setMeta({ error: "One of email or phone number is required" });
				mobileNum.setMeta({ error: "One of email or phone number is required" });
				email.setShowError(true);
				mobileNum.setShowError(true);
				return false;
			}
			if (email.value) {
				let err = isEmailValid({ value: email.value });
				if (err) {
					email.setMeta({ error: err });
					email.setShowError(true);
					return false;
				}
			}
			if (mobileNum.value) {
				let err = isPhoneNumberValid({ value: String(mobileNum.value) });
				if (err) {
					mobileNum.setMeta({
						error: err,
					});
					mobileNum.setShowError(true);
					return false;
				}
			}
		}
		if (pinCode.value) {
			let err = isPincodeValid({ value: String(pinCode.value) });
			if (err) {
				pinCode.setMeta({
					error: err,
				});
				pinCode.setShowError(true);
				return false;
			}
		}
		if (
			!title.isValid ||
			!state.isValid ||
			!district.isValid ||
			!gender.isValid ||
			!talukOrTehsil.isValid ||
			!pinCode.isValid ||
			!address.isValid
		) {
			return false;
		}

		return true;
	};

	const emailModifyCheck = async () => {
		if (!paramsUserId.startsWith("editSelf")) return;
		let trimmedEmail = email.value.trim();
		if (!trimmedEmail) return;
		email.setValue(trimmedEmail);
		if (!isEmail(trimmedEmail)) {
			email.setMeta({ error: isEmailValid({ value: trimmedEmail }) });
			return email.setShowError(true);
		}

		if (emailWhenUntouched.current !== trimmedEmail) {
			setOtpInProgress(true);
			let res = await generateOtpModifyEmailOrPhone({
				email: trimmedEmail,
			});

			if (res.data?.success) {
				setOtpInProgress(false);
				setShowEmailOtpLink(true);
				setShowSetProfileModal(true);
			} else if (res.data?.success === false) {
				setOtpInProgress(false);
			}
		}
	};
	const phoneNumModifyCheck = async () => {
		if (!paramsUserId.startsWith("editSelf")) return;
		if (!mobileNum.value) return;
		if (!isNumeric(mobileNum.value) && String(mobileNum.value).length !== 10) {
			mobileNum.setMeta({
				error: isPhoneNumberValid({ value: mobileNum.value }),
			});
			return mobileNum.setShowError(true);
		}
		if (phoneNumWhenUntouched.current !== mobileNum.value) {
			setOtpInProgress(true);
			setShowSmsOtpLink(true);
			setShowSetProfileModal(true);
			let res = await generateOtpModifyEmailOrPhone({
				phoneNumber: mobileNum ? "+91" + mobileNum.value : "",
			});
			setOtpInProgress(false);
		}
	};
	const onSubmit = async () => {
		document.getElementById("special_email").blur();
		document.getElementById("special_phoneNum").blur();
		if (otpInProgress) return;
		if (formErrCleanupValidation()) {
			if (file) {
				let res = await uploadFile(file.file, "Certificate");
				if (res.data.success) {
					let imageUrl = res.data.data;
					submitPersonalInfo(imageUrl);
				}
			} else submitPersonalInfo();
		}
	};

	const submitPersonalInfo = async (imageUrl) => {
		let localUserId = userId;
		let phoneNumber = mobileNum?.value
			? "+91" + mobileNum?.value.slice(-10)
			: "";
		emailModifyCheck();
		phoneNumModifyCheck();
		if (paramsUserId.startsWith("create")) {
			let user = await createUser({
				email: email.value.toLowerCase(),
				phoneNumber: phoneNumber,
			});
			if (user.data.success) {
				setUserId(user.data.data.id);
				localUserId = user.data.data.id
			} else {
				console.log(user.message);
			}
		}
		console.log("mobileNum.value", phoneNumber);
		setPincode(pinCode.value);
		let payload = {
			userId: localUserId,
			address: {
				city: cityOrVillage.value,
				country: "India",
				district: district.value,
				pinCode: pinCode.value,
				state: state.value,
				taluk: talukOrTehsil.value,
				address: address.value,
			},
			email: email.value,
			dob: dob,
			joinType: 'ADMIN',
			fullName: fullName.value,
			gender: gender.value,
			title: title.value,
			rollNumber: rollNumber.value,
			phoneNumber: phoneNumber,
			profileImageurl: imageUrl ? imageUrl : profileImageurl,
		}
		if(studentId){
			payload['_id'] = studentId
		}
		let res = await saveStudent(payload);
		if (res.data.success) {
			setStudentId(res.data.data._id);
			console.log("data", res.data);
			if (paramsUserId.startsWith("editSelf") && isFirstTime) {
				setCurrentUser({
					...currentUser,
					phoneNumber: "+91" + mobileNum.value,
					email: email.value,
				});
			}
			setCheckpassed(true);
			handleNext();
		}
	};
	return (
		<>
			<Box>
				<FlexContainer al="flex-start">
					<Badge
						overlap="circular"
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						badgeContent={<EditIcon style={{ fontSize: 12 }} />}
						backgroundColor="#82D2DA"
						className="avatar-badge"
					>
						<Avatar
							src={file?.source ? file?.source : profileImageurl}
							sx={{ width: 60, height: 60, margin: 0 }}
							onClick={() => selectFile({ accept: "image/*" })}
						/>
					</Badge>
					<Box
						width={matches ? "300px" : "600px"}
						sx={{ paddingRight: "2rem" }}
					>
						<FlexContainer al="flex-start">
							<Input
								label="Title"
								select
								options={titles}
								width="100px"
								{...title.getInputProps()}
								errorText={title.showError && title.meta.error}
							/>
							<span style={{ color: "red", fontWeight: "bold" }}>*</span>
							<HorizontalSpacer />
							<Input
								label="Full Name"
								{...fullName.getInputProps()}
								errorText={fullName.showError && fullName.meta.error}
							/>
							<span style={{ color: "red", fontWeight: "bold" }}>*</span>
						</FlexContainer>
						<VerticalSpacer />
						{console.log("{...email.getInputProps()}", {
							...email.getInputProps(),
						})}
						<Input
							label="E-mail Address"
							{...email.getInputProps()}
							errorText={email.showError && email.meta.error}
							onBlur={emailModifyCheck}
							id="special_email"
							shrink={false}
							width="538px"
						/>
						<span style={{ color: "red", fontWeight: "bold" }}>*</span>

						<VerticalSpacer />
						<Input
							label="Your 10-digit mobile no. "
							{...mobileNum.getInputProps()}
							errorText={mobileNum.showError && mobileNum.meta.error}
							onBlur={phoneNumModifyCheck}
							id="special_phoneNum"
							width="538px"
						/>
						<span style={{ color: "red", fontWeight: "bold" }}>*</span>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Box width="100%">
								<BasicDatePicker
									label="Date of Birth"
									value={dob}
									setValue={setDob}
								/>
							</Box>
							<HorizontalSpacer />
							<FlexContainer jc="flex-start">
								<Input
									width="130px"
									label="Gender"
									select
									options={genders}
									{...gender.getInputProps()}
									errorText={gender.showError && gender.meta.error}
								/>
							</FlexContainer>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Input label="Country" readOnly value="India" />
							<HorizontalSpacer />
							<Input
								label="State"
								select
								options={state_dists.states.map((state) => ({
									value: state.state,
									label: state.state,
								}))}
								{...state.getInputProps()}
								errorText={state.showError && state.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Input
								label="District"
								select
								options={
									state.value
										? state_dists.states
												.find((st) => st.state === state.value)
												?.districts?.map((dist) => ({
													value: dist,
													label: dist,
												}))
										: []
								}
								{...district.getInputProps()}
								errorText={district.showError && district.meta.error}
							/>
							<HorizontalSpacer />
							<Input
								label="Taluk / Tehsil"
								{...talukOrTehsil.getInputProps()}
								errorText={talukOrTehsil.showError && talukOrTehsil.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Input
								label="City / Village"
								{...cityOrVillage.getInputProps()}
								errorText={cityOrVillage.showError && cityOrVillage.meta.error}
							/>
							<HorizontalSpacer />
							<Input
								label="Pin Code"
								{...pinCode.getInputProps()}
								errorText={pinCode.showError && pinCode.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer>
							<Input
								label="Address"
								{...address.getInputProps()}
								errorText={address.showError && address.meta.error}
							/>
							<HorizontalSpacer />
							<Input
								label="Roll Number"
								{...rollNumber.getInputProps()}
								errorText={rollNumber.showError && rollNumber.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
					</Box>
				</FlexContainer>
			</Box>
			<ProfileFormFooter
				steps={steps}
				index={index}
				handleSave={onSubmit}
				handleNext={handleNext}
				handleBack={handleBack}
				nextdisabled={showSmsOtplink || showEmailOtplink || checkpassed}
			/>
			<CustomModal
				open={showSetProfileModal}
				setOpen={setShowSetProfileModal}
				closeOnOutsideClick={false}
			>
				<Card
					sx={{
						px: 6,
						pt: 7,
						pb: 3,
						width: "45vw",
						position: "relative",
						margin: "auto",
						mt: "10vh",
					}}
				>
					{showEmailOtplink ? (
						<OtpView
							forAuth={false}
							email={email}
							onSuccess={() => {
								setShowSetProfileModal(false);
								setShowEmailOtpLink(false);
							}}
							revertChange={() => {
								email.setValue(
									emailWhenUntouched.current ? emailWhenUntouched.current : ""
								);
								setShowSetProfileModal(false);
								setShowEmailOtpLink(false);
							}}
						/>
					) : showSmsOtplink ? (
						<OtpView
							forAuth={false}
							onSuccess={() => {
								setShowSetProfileModal(false);
								setShowSmsOtpLink(false);
							}}
							phoneNumber={mobileNum}
							revertChange={() => {
								mobileNum.setValue(
									phoneNumWhenUntouched.current
										? phoneNumWhenUntouched.current
										: ""
								);
								setShowSetProfileModal(false);
								setShowSmsOtpLink(false);
							}}
						/>
					) : null}
				</Card>
			</CustomModal>
		</>
	);
};

export default PersonalInfo;
