import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

const useStudentHelper = () => {
	const saveStudent = async (body) => {
		let endpoint = `${environment.studentsUrl}`;
		return await axiosInstance().post(endpoint, body);
	};
	const getStudents = async (schoolId) => {
		let endpoint = `${environment.studentsUrl}/search`;
		if(schoolId){
			endpoint += `?schoolId=${schoolId}`
		}
		return await axiosInstance().get(endpoint);
	};
	const getStudent = async (id) => {
		let endpoint = `${environment.studentsUrl}/${id}`;
		return await axiosInstance().get(endpoint);
	};

	const deleteStudent = async (id) => {
		let endpoint = `${environment.studentsUrl}/${id}`;
		return await axiosInstance().delete(endpoint);
	};
	return { saveStudent, getStudents, getStudent, deleteStudent };
};

export default useStudentHelper;
