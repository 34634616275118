import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Input } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import useAdminHelper from "../../hooks/useAdminHelper"

const BulkUser = ({handleNext,setLoading}) => {

    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("");
    const { uploadFile, importFile } = useAdminHelper()
    const handleClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleBulkImport = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append(
            "file",
            file,
            file.name
        )
        let res = await uploadFile(formData)
        if (res?.data?.success) {
            handleClose();
            // setLoading(false);

            let fileUrl = res?.data?.data;
            let resp = await importFile(fileUrl)
            console.log(resp)   
            if(resp){
                setLoading(false);
            }
 
        }
    }
    return (
        <div>
            <Button onClick={handleClick} style={{ borderRadius: '50px', marginRight: '20px' }} variant='contained'>Create Students in bulk</Button>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Upload Excel File</DialogTitle>
                <DialogContent>
                    <Link to="/CreateStudents.xlsx" target="_blank" download>Download Template Excel</Link>
                    <Divider />
                    <Box sx={{
                        minHeight: 400,
                        minWidth: 400,
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid grey",
                        borderRadius: "10px",
                        mergin: "2px"
                    }}>
                        <Input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => setFile(e.target.files[0])}
                            sx={{
                                margin: 20
                            }}
                            type="file" />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleBulkImport}>Upload</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default BulkUser