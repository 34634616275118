import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

const useOnboardingHelper = () => {
	const savePersonalInfo = async (body) => {
		let endpoint = environment.userBaseUrl;
		if (body.userId.startsWith("editSelf")) {
			endpoint += Endpoints.PERSONAL_INFO;
			return await axiosInstance().put(endpoint, body);
		} else {
			endpoint += "admin/" + Endpoints.PERSONAL_INFO + "/" + body.userId;
			return await axiosInstance().put(endpoint, body);
		}
	};
	const getPersonalInfo = async (userId) => {
		let endpoint = environment.userBaseUrl;
		if (userId.startsWith("editSelf")) {
			endpoint += Endpoints.PERSONAL_INFO;
		} else {
			endpoint += "admin/" + Endpoints.PERSONAL_INFO + "/" + userId;
		}
		console.log(endpoint);
		return await axiosInstance(false).get(endpoint);
	};

	const saveWorkExperience = async (infoId, body) => {
		let endpoint = environment.userBaseUrl;
		endpoint += "admin" + Endpoints.WORK_EXPERIENCE + "/" + body.userId;
		if (infoId !== "") {
			endpoint += "/" + infoId;
			return await axiosInstance().put(endpoint, body);
		} else {
			return await axiosInstance().post(endpoint, body);
		}
	};

	const getWorkExperience = async (userId) => {
		let endpoint = environment.userBaseUrl;
		endpoint += "admin/" + userId + Endpoints.WORK_EXPERIENCE;
		console.log(endpoint);
		return await axiosInstance(false).get(endpoint);
	};

	const deleteWorkExperience = async (userId, infoId) => {
		let endpoint = environment.userBaseUrl;
		endpoint +=
			"admin/" + Endpoints.WORK_EXPERIENCE + "/" + userId + "/" + infoId;
		console.log(endpoint);
		return await axiosInstance(false).delete(endpoint);
	};

	const saveEducationInfo = async (infoId, body) => {
		let endpoint = environment.userBaseUrl;
		if (body.userId) {
			endpoint += "admin/" + Endpoints.EDUCATION_INFO + "/" + body.userId;
		} else {
			endpoint += Endpoints.EDUCATION_INFO;
		}
		if (infoId !== "") {
			endpoint += "/" + infoId;
			return await axiosInstance().put(endpoint, body);
		} else {
			return await axiosInstance().post(endpoint, body);
		}
	};

	const getEducationInfo = async (userId) => {
		console.log(userId);
		let endpoint = environment.userBaseUrl;
		endpoint += "admin/" + userId + "/" + Endpoints.EDUCATION_INFO;
		console.log(endpoint);
		return await axiosInstance(false).get(endpoint);
	};

	const deleteEducationInfo = async (userId, infoId) => {
		console.log(userId);
		let endpoint = environment.userBaseUrl;
		endpoint +=
			"admin/" + Endpoints.EDUCATION_INFO + "/" + userId + "/" + infoId;
		console.log(endpoint);
		return await axiosInstance(false).delete(endpoint);
	};

	const generateOtpModifyEmailOrPhone = async (body) => {
		const endpoint = environment.userBaseUrl + Endpoints.GENERATE_UPDATE_OTP;
		return await axiosInstance().post(endpoint, body);
	};

	const validateOtpForModifyEmailOrPhone = async (body) => {
		const endpoint = environment.userBaseUrl + Endpoints.VALIDATE_UPDATE_OTP;
		return axiosInstance().post(endpoint, body);
	};
	const uploadFile = async (file, purpose) => {
		const endpoint =
			environment.userBaseUrl + Endpoints.UPLOAD_FILE + "?purpose=" + purpose;
		let formdata = new FormData();
		formdata.append("file", file);
		return axiosInstance().post(endpoint, formdata);
	};
	return {
		getPersonalInfo,
		savePersonalInfo,
		saveEducationInfo,
		deleteEducationInfo,
		getEducationInfo,
		saveWorkExperience,
		getWorkExperience,
		deleteWorkExperience,
		generateOtpModifyEmailOrPhone,
		validateOtpForModifyEmailOrPhone,
		uploadFile,
	};
};

export default useOnboardingHelper;
