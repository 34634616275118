import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import { useState } from "react";
import { useQuery } from "react-query";
import useSchoolHelper from "../../hooks/useSchoolHelper";
import GlobalLayout from "../../components/layout/GlobalLayout";

const HorizontalSpacer = () => <Spacer width={1} />;
const VerticalSpacer = () => <Spacer height={2} />;

const User = () => {
	let { addAdmin } = useSchoolHelper();

	const [email, setEmail] = useState("");
	const [fullName, setName] = useState("");
	const [gender, setGender] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [title, setTitle] = useState("");
	const [password, setPassword] = useState("");
	const [roles, setRoles] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = {
			email: email,
			fullName: fullName,
			gender: gender,
			phoneNumber: phoneNumber,
			title: title,
			password: password,
			userRole: roles,
		};

		console.log("User Data:", data);
		let res = await addAdmin(data);
	};

	return (
		<>
			<GlobalLayout>
				<Grid
					container
					m={2}
					pr={3}
					style={{ marginTop: 100, justifyContent: "center", display: "flex" }}
				>
					<Box>
						<FlexContainer>
							<Box width={"500px"} sx={{ paddingRight: "2rem" }}>
								<FlexContainer al="flex-start">
									<Input
										label="User Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<span style={{ color: "red", fontWeight: "bold" }}>*</span>
								</FlexContainer>
								<VerticalSpacer />
								<FlexContainer al="flex-start">
									<Input
										label="User Name"
										value={fullName}
										onChange={(e) => setName(e.target.value)}
									/>
									<span style={{ color: "red", fontWeight: "bold" }}>*</span>
								</FlexContainer>
								<VerticalSpacer />
								<FlexContainer alignItems="flex-start">
									<Box marginLeft={-4}>
										<label style={{ paddingRight: "6px", color: "grey" }}>
											Gender
										</label>
										<span style={{ color: "red", fontWeight: "bold" }}>*</span>
									</Box>
									<Box marginLeft={10}>
										<FormControlLabel
											control={
												<Checkbox
													checked={gender === "male"}
													onChange={(e) =>
														setGender(e.target.checked ? "male" : "")
													}
													value="male"
													color="primary"
												/>
											}
											label="Male"
										/>
									</Box>
									<Box marginLeft={10}>
										<FormControlLabel
											control={
												<Checkbox
													checked={gender === "female"}
													onChange={(e) =>
														setGender(e.target.checked ? "female" : "")
													}
													value="female"
													color="primary"
												/>
											}
											label="Female"
										/>
									</Box>
								</FlexContainer>
								<VerticalSpacer />
								<FlexContainer al="flex-start">
									<Input
										label="Phone Number"
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
									/>
									<span style={{ color: "red", fontWeight: "bold" }}>*</span>
								</FlexContainer>
								<VerticalSpacer />
								<FlexContainer al="flex-start">
									<Box marginLeft={-6}>
										<label style={{ paddingRight: "4px", color: "grey" }}>
											Title
										</label>
										<span style={{ color: "red", fontWeight: "bold" }}>*</span>
									</Box>
									<Box marginLeft={12}>
										<FormControlLabel
											control={
												<Checkbox
													checked={title === "Mr"}
													onChange={(e) =>
														setTitle(e.target.checked ? "Mr" : "")
													}
													value="Mr"
													color="primary"
												/>
											}
											label="Mr."
										/>
									</Box>
									<Box marginLeft={12}>
										<FormControlLabel
											control={
												<Checkbox
													checked={title === "Ms"}
													onChange={(e) =>
														setTitle(e.target.checked ? "Ms" : "")
													}
													value="Ms"
													color="primary"
												/>
											}
											label="Ms."
										/>
									</Box>
								</FlexContainer>
								<VerticalSpacer />
								<FlexContainer al="flex-start">
									<Input
										type="password"
										label="User Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<span style={{ color: "red", fontWeight: "bold" }}>*</span>
								</FlexContainer>
								<VerticalSpacer />
								<FlexContainer al="flex-start">
									<Select
										labelId="role-label"
										value={roles}
										onChange={(e) => setRoles(e.target.value)}
										label="User Role"
									>
										<InputLabel id="role-label">User Role</InputLabel>
										<MenuItem value="SUPER_ADMIN">Superadmin access</MenuItem>
										<MenuItem value="SUPER_ADMIN">Lo-Admin access</MenuItem>
										<MenuItem value="AUTHOR">Content manager access</MenuItem>
										<MenuItem value="TEACHER">Coordinator access</MenuItem>
									</Select>

									{/* <Input
                         label="User Roles"
                         value={roles} onChange={(e) => setRoles(e.target.value)}
                        /><span style={{ color: "red", fontWeight: "bold" }}>*</span> */}
								</FlexContainer>
								<VerticalSpacer />
							</Box>
						</FlexContainer>
						<FlexContainer>
							<Button
								style={{ borderRadius: "50px", marginRight: "20px" }}
								variant="contained"
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</FlexContainer>
					</Box>
				</Grid>
			</GlobalLayout>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	formControlRoot: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		width: "200px",
		flexWrap: "wrap",
		flexDirection: "row",
		border: "2px solid lightgray",
		padding: 4,
		borderRadius: "4px",
		"&> div.container": {
			gap: "6px",
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		"& > div.container > span": {
			backgroundColor: "gray",
			padding: "1px 3px",
			borderRadius: "4px",
		},
	},
}));
export default User;
