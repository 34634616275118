import { Box, Button, Grid, Paper, TextField, useTheme } from "@mui/material";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import state_dists from "../../data/state_dist.json";
import { useField } from "../../hooks/useField";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";
import { Input as Inp } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { isEmail, isNumeric } from "validator";
import { makeStyles } from "@mui/styles";
import {
	isEmailValid,
	isPhoneNumberValid,
	isPincodeValid,
	isRequired,
} from "../../utils/validators";
import ProfileFormFooter from "../profile/ProfileFormFooter";
import { useEffect, useState, useRef } from "react";
import { useStore } from "../../stores";
import axios from "axios";
import useSchoolHelper from "../../hooks/useSchoolHelper";
import { useNavigate } from "react-router-dom";
import { environment } from "../../environments/environment";
import { useQuery } from "react-query";

const HorizontalSpacer = () => <Spacer width={1} />;
const VerticalSpacer = () => <Spacer height={2} />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const School = () => {
	const theme = useTheme();

	const currentUser = useStore((state) => state.currentUser);
	let authToken = "Bearer " + currentUser.access_token;
	const emailWhenUntouched = useRef();
	const phoneNumWhenUntouched = useRef();
	const name = useField({ validate: isRequired });
	const contactemail = useField({});
	const mobileNum = useField({});
	const state = useField({});
	const district = useField({});
	const zone = useField({});
	const cityOrVillage = useField({});
	const pincode = useField({ validate: isRequired});
	const address = useField({});
	const [pin, setPincode] = useState("");
	const milestone = useField({});
	const sentiment = useField({});
	const resMode = useField({});
	const type = useField({});
	let { addSchool, getSchoolInfo, updateSchool } = useSchoolHelper();
	const resModeList = ["SELF", "ADMIN", "BOTH"];
	const [uerId, setUserId] = "";
	const query = new URLSearchParams(window.location.search);
	const schoolId = query.get("schoolId");

	const { data: SchoolData } = useQuery(
		["school", schoolId],
		() => getSchoolInfo(schoolId),
		{ enabled: !!schoolId }
	);

	useEffect(() => {
		if (SchoolData?.data?.success && SchoolData?.data?.data?._id) {
			if (SchoolData.data.data["contactemail"]) {
				contactemail.setValue(SchoolData.data.data["contactemail"]);
				emailWhenUntouched.current = SchoolData.data.data["contactemail"];
			}
			if (SchoolData.data.data["othercontactnumbers"]) {
				mobileNum.setValue(SchoolData.data.data["othercontactnumbers"]);
			}
			if (SchoolData.data.data["milestone"]) {
				milestone.setValue(SchoolData.data.data["milestone"]);
			}
			if (SchoolData.data.data["sentiment"]) {
				sentiment.setValue(SchoolData.data.data["sentiment"]);
			}
			if (SchoolData.data.data["resMode"]) {
				resMode.setValue(SchoolData.data.data["resMode"]);
			}
			if (SchoolData.data.data["type"]) {
				type.setValue(SchoolData.data.data["type"]);
			}
			if (SchoolData.data.data["city"]) {
				cityOrVillage.setValue(SchoolData.data.data["city"]);
			}
			if (SchoolData.data.data["state"]) {
				state.setValue(SchoolData.data.data["state"]);
			}
			if (SchoolData.data.data["district"]) {
				district.setValue(SchoolData.data.data["district"]);
			}
			if (SchoolData.data.data["contactemail"]) {
				contactemail.setValue(SchoolData.data.data["contactemail"]);
				emailWhenUntouched.current = SchoolData.data.data["contactemail"];
			}
			if (SchoolData.data.data["name"]) {
				name.setValue(SchoolData.data.data["name"]);
			}
			if (SchoolData.data.data["pincode"]) {
				pincode.setValue(SchoolData.data.data["pincode"]);
				setPincode(SchoolData.data.data["pincode"]);
			}
			if (SchoolData.data.data["address"]) {
				address.setValue(SchoolData.data.data["address"]);
			}
			if (SchoolData.data.data["othercontactnumbers"]) {
				mobileNum.setValue(SchoolData.data.data["othercontactnumbers"]);
			}
		}
	}, [SchoolData]);
	const emailModifyCheck = async () => {
		let trimmedEmail = contactemail.value.trim();
		if (!trimmedEmail) return;
		contactemail.setValue(trimmedEmail);
		if (!isEmail(trimmedEmail)) {
			contactemail.setMeta({ error: isEmailValid({ value: trimmedEmail }) });
			return contactemail.setShowError(true);
		}
	};

	const phoneNumModifyCheck = async () => {
		if (!mobileNum.value) return;
		if (!isNumeric(mobileNum.value) && String(mobileNum.value).length !== 10) {
			mobileNum.setMeta({
				error: isPhoneNumberValid({ value: mobileNum.value }),
			});
			return mobileNum.setShowError(true);
		}
	};

	const handleChangeInput = (id, event) => {
		const newInputFields = contactPersons.map((i) => {
			if (id === i.id) {
				i[event.target.name] = event.target.value;
			}
			return i;
		});

		setContactPersons(newInputFields);
	};

	const [contactPersons, setContactPersons] = useState([
		{
			id: uuidv4(),
			name: "",
			phoneNum: "",
			password: "",
			additionalNumbers: [],
			cur: "",
		},
	]);
	const handleAddFields = () => {
		setContactPersons([
			...contactPersons,
			{
				id: uuidv4(),
				name: "",
				phoneNum: "",
				password: "",
				additionalNumbers: [],
				cur: "",
			},
		]);
	};

	const handleRemoveFields = (id) => {
		const values = [...contactPersons];
		values.splice(
			values.findIndex((value) => value.id === id),
			1
		);
		setContactPersons(values);
	};
	const handleKeyUp = (id, e) => {
		console.log(e.keyCode);
		if (e.keyCode == 32) {
			const newInputFields = contactPersons.map((i) => {
				if (id === i.id) {
					i.additionalNumbers = [...i.additionalNumbers, e.target.value];
					i.cur = "";
				}
				return i;
			});
			setContactPersons(newInputFields);
		}
	};
	const navigate = useNavigate();
	const handleDelete = (id, item, index) => {
		const newInputFields = contactPersons.map((i) => {
			if (id === i.id) {
				let arr = [...i.additionalNumbers];
				arr.splice(index, 1);
				i.additionalNumbers = arr;
			}
			return i;
		});
		setContactPersons(newInputFields);
	};
	const classes = useStyles();

	const handleSubmit = async () => {

		if (pincode.value) {
			let err = isPincodeValid({ value: String(pincode.value) });
			if (err) {
				pincode.setMeta({
					error: err,
				});
				pincode.setShowError(true);
				return false;
			}
		} else {
			pincode.setMeta({
				error: "Pincode is mandatory",
			});
			pincode.setShowError(true);
			return false;
		}
		let body = {
			...SchoolData?.data?.data,
			name: name.value,
			address: address.value,
			city: cityOrVillage.value,
			contactemail: contactemail.value,
			milestone: milestone.value,
			othercontactnumbers: mobileNum.value,
			pincode: pincode.value,
			zone: zone.value,
			district: district.value,
			state: state.value,
			salesChannelId: currentUser.id,
			sentiment: sentiment.value,
			resMode: resMode.value,
			type: type.value,
		};
		let res = await addSchool(body);
		if (res?.data?.data) {
			navigate("/schools");
		}
	};

	return (
		<>
			<Box>
				<FlexContainer>
					<Box width={"1000px"} sx={{ paddingRight: "2rem" }}>
						<FlexContainer al="flex-start">
							<Input
								label="School Name"
								{...name.getInputProps()}
								errorText={name.showError && name.meta.error}
							/>
							<span style={{ color: "red", fontWeight: "bold" }}>*</span>
						</FlexContainer>
						<VerticalSpacer />

						<Input
							label="School E-mail Address"
							{...contactemail.getInputProps()}
							errorText={contactemail.showError && contactemail.meta.error}
							onBlur={emailModifyCheck}
							id="special_email"
							shrink={false}
							width="538px"
						/>
						<span style={{ color: "red", fontWeight: "bold" }}>*</span>

						<VerticalSpacer />
						<Input
							label="Contact Number"
							{...mobileNum.getInputProps()}
							errorText={mobileNum.showError && mobileNum.meta.error}
							onBlur={phoneNumModifyCheck}
							id="special_phoneNum"
							width="538px"
						/>
						<span style={{ color: "red", fontWeight: "bold" }}>*</span>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Input label="Country" readOnly value="India" />
							<HorizontalSpacer />
							<Input
								label="State"
								select
								options={state_dists.states.map((state) => ({
									value: state.state,
									label: state.state,
								}))}
								{...state.getInputProps()}
								errorText={state.showError && state.meta.error}
							/>
							<span style={{ color: "red", fontWeight: "bold" }}>*</span>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Input
								label="District"
								select
								options={
									state.value
										? state_dists.states
												.find((st) => st.state === state.value)
												?.districts?.map((dist) => ({
													value: dist,
													label: dist,
												}))
										: []
								}
								{...district.getInputProps()}
								errorText={district.showError && district.meta.error}
							/>
							<span style={{ color: "red", fontWeight: "bold" }}>*</span>
							<HorizontalSpacer />
							<Input
								label="Zone"
								{...zone.getInputProps()}
								errorText={zone.showError && zone.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer al="flex-start">
							<Input
								label="City / Village"
								{...cityOrVillage.getInputProps()}
								errorText={cityOrVillage.showError && cityOrVillage.meta.error}
							/>
							<HorizontalSpacer />
							<Input
								label="Pin Code"
								{...pincode.getInputProps()}
								errorText={pincode.showError && pincode.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer>
							<Input
								label="Address"
								{...address.getInputProps()}
								errorText={address.showError && address.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer>
							<Input
								label="Milestone"
								select
								// options={milestoneList?.map((milestone) => ({
								// 	label: milestone.key,
								// 	value: milestone.name,
								// }))}
								{...milestone.getInputProps()}
								errorText={milestone.showError && milestone.meta.error}
							/>
							<HorizontalSpacer />
							<Input
								label="Sentiment"
								select
								// options={sentimentList?.map((sentiment) => ({
								// 	label: sentiment.name,
								// 	value: sentiment.name,
								// }))}
								{...sentiment.getInputProps()}
								errorText={sentiment.showError && sentiment.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
						<FlexContainer>
							<Input
								label="Registration Mode"
								select
								options={resModeList.map((resMode) => ({
									label: resMode,
									value: resMode,
								}))}
								{...resMode.getInputProps()}
								errorText={resMode.showError && resMode.meta.error}
							/>
							<HorizontalSpacer />
							<Input
								label="Type of School"
								select
								options={[
									{
										label: "K12",
										value: "K12",
									},
									{
										label: "Tech",
										value: "Tech",
									},
								]}
								{...type.getInputProps()}
								errorText={type.showError && type.meta.error}
							/>
						</FlexContainer>
						<VerticalSpacer />
					</Box>
				</FlexContainer>
				<FlexContainer>
					<Button
						style={{ borderRadius: "50px", marginRight: "20px" }}
						variant="contained"
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</FlexContainer>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	formControlRoot: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		width: "300px",
		flexWrap: "wrap",
		flexDirection: "row",
		border: "2px solid lightgray",
		padding: 4,
		borderRadius: "4px",
		"&> div.container": {
			gap: "6px",
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		"& > div.container > span": {
			backgroundColor: "gray",
			padding: "1px 3px",
			borderRadius: "4px",
		},
	},
}));

export default School;
