import { Endpoints } from "../constants/endpoints";
import { environment } from "../environments/environment";
import axiosInstance from "../services/api.service";

export const studentInfoObj = (schoolId,page,countPerPage,grade) => {
    let obj =  {
        role: "STUDENT",
        sortBy: "CREATEDAT",
        sortOrder: "DESC",
        incDeleted: true,
    }
    if(schoolId){
        obj.schoolId = schoolId
    }
    if(grade){
        obj.grade = grade
    }
    return obj;
}
export const adminInfoObj = {
    role: "ADMIN",
    sortBy: "CREATEDAT",
    sortOrder: "DESC",
    incDeleted: true,
    pageSize: 100,
    pageNumber: 0
}
export const convertDatainString = (data) => {
    console.log('data', data)
    var dataString = '?';
    for (const key in data) {
        dataString += `${key}=${data[key]}&`
    }
    return dataString
}
export const convertToFormData = (data) => {
    let formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key])
    }
    return formData
}
export const useFeatureHelper = () => {
    const getCategory = async () => {
        const endpoint = environment.userBaseUrl + Endpoints.GET_MasterData + '?category=category&searchText=&pageNumber=0&pageSize=1000';
        return await axiosInstance().get(endpoint);
    };
    const getTags = async () => {
        const endpoint = environment.userBaseUrl + Endpoints.GET_MasterData + '?category=tag&searchText=&pageNumber=0&pageSize=1000';
        return await axiosInstance().get(endpoint);
    };
    const getSkill = async () => {
        const endpoint = environment.userBaseUrl + Endpoints.GET_SKILL;
        return await axiosInstance().get(endpoint);
    };
    const getLos = async () => {
        const endpoint = environment.userBaseUrl + Endpoints.Los + '?account=DEV';
        return await axiosInstance().get(endpoint);
    };

    const getQb = async (body) => {
        const endpoint = environment.userBaseUrl + Endpoints.GET_QB + convertDatainString({ ...body });
        return await axiosInstance().get(endpoint);
    };
    const deleteQb = async (id) => {
        const endpoint = environment.userBaseUrl + Endpoints.GET_QB + '/' + id;
        return await axiosInstance().delete(endpoint);
    };

    return {
        convertDatainString,
        convertToFormData,
        getCategory,
        getTags,
        getSkill,
        getLos,
        getQb,
        deleteQb,
    };
};
