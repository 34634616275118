import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react'
import useAdminHelper from "../../hooks/useAdminHelper"
import useStudentHelper from '../../hooks/useStudentHelper';

const ResetPasswordModal = ({open,setOpen,userId,studentId}) => {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const {saveStudent} = useStudentHelper();

    const [errorMessage, setErrorMessgae] = useState();
    const { updateUser } = useAdminHelper();
    const [nameErrorMessage, setNameErrorMessage] = useState();
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
    const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
    const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
    const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

    const handleClose = () => {
        setOpen(false);
        setNameErrorMessage(null)
        setPassword1("")
        setPassword2("")
    }

    const resetPassword = async () => {
        if(password1!=password2){
            setNameErrorMessage("Please enter the same password as above")
        } else {
            let res = await updateUser(userId,{"password":password1})
            await saveStudent({_id:studentId, securityPin:password1})

            if(res?.data?.success){
                console.log("reset password successful");
                handleClose()
            }
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password1"
                        label="New Password"
                        type={showPassword1 ? "text" : "password"}
                        fullWidth
                        variant="standard"
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword1}
                                  onMouseDown={handleMouseDownPassword1}
                                >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password2"
                        label="Re-enter Password"
                        type={showPassword2 ? "text" : "password"}
                        fullWidth
                        variant="standard"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        error={!!nameErrorMessage}
                        helperText={!!nameErrorMessage ? nameErrorMessage : ""}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword2}
                                  onMouseDown={handleMouseDownPassword2}
                                >
                                  {showPassword2? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={resetPassword}>Reset</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ResetPasswordModal