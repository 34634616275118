import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { Link, Box } from '@mui/material';
import { Button, Grid } from '@mui/material';
import GlobalLayout from '../../components/layout/GlobalLayout';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { CustomSnackbar } from '../../components/elements/CustomSnackbar';
import AlertMessageModal from '../../components/attention/AlertMessageModal';
import { useNavigate } from 'react-router-dom';
import useRoleHelper from '../../hooks/useRoleHelper';

const Roles = () => {

  const navigate = useNavigate();

  const [currentRowId, setCurrentRowId] = React.useState(null);

  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [delMessage, setDelMessage] = React.useState(false);

  const [tableArr, setTableArr] = React.useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(1);
  const [page, setPage] = useState(1);

  const { getRoles, deleteRole } = useRoleHelper();

  const { data: rolesData, isLoading: userLoader } = useQuery([`RolesData`, page, countPerPage], () => getRoles(), { enabled: true, retry: false })
  useEffect(() => {
    console.log(tableArr);
    if (rolesData) {
      setTableArr(rolesData?.data?.data)
      setTableTotalCount()
    }
  }, [rolesData, tableArr])

  const deleteRow = () => {
    deleteMutate(currentRowId)
  }

  const { mutate: deleteMutate } = useMutation(deleteRole, {
    onSuccess: (data, variables, context) => onSuccessUserDelete(data, variables, context),
    onError: (data, variables, context) => onErrorUserDelete(data, variables, context)
  })

  const onSuccessUserDelete = (data, variables, context) => {
    if (data) {
      setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" });
      setDelMessage(false);
    }
  }

  const onErrorUserDelete = (error, variables, context) => {
    error && setSnakeBarProps({ snackbarFlag: true, msz: error.message, type: "error" })
  }

  return (
    <GlobalLayout loader={userLoader}>
      <Grid container m={2} pr={3} style={{ marginTop: 100 }}>
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center">
          <Button onClick={() => navigate('/roles/create')} style={{ borderRadius: '50px', marginRight: '20px' }} variant='contained'>Add New Role</Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <MaterialTable
            columns={[
              { title: 'Role Name', field: 'roleTitle' },
              {
                title: 'Actions ', field: 'status',
                render: rowData => {
                  return (
                    <Box>
                      <Link style={{ marginRight: '20px' }} href={'/roles/' + rowData.id}>Edit</Link>
                      <Link href='#' onClick={() => { setDelMessage(true); setCurrentRowId(rowData.id); }}>Delete</Link>
                    </Box>
                  )
                }
              },
            ]}

            totalCount={tableTotalCount}
            data={tableArr}
            page={page - 1}
            onChangePage={(page) => { setPage(page + 1); }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}
            title="Roles"
            options={{
              pageSize: 100,
              headerStyle: {
                backgroundColor: '#82D2DA'
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {
            delMessage &&
            <AlertMessageModal
              setDelMessage={setDelMessage}
              alertMessage='Do you want to remove the user?'
              okbtnFlag='true'
              cancelBtnFlag='true'
              onClose={() => { setDelMessage(false) }}
              onClick={() => { deleteRow() }}
            />
          }
        </Grid>
      </Grid>
      {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
    </GlobalLayout>
  );
};

export default Roles;