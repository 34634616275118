import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonCustom from "../../components/elements/ButtonCustom";
import FlexContainer from "../../components/elements/FlexContainer";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useField } from "../../hooks/useField";
import { useStore } from "../../stores";
import { backIconStyles } from "../../theme/customStyles";
import { isEmailValid, isRequired } from "../../utils/validators";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Button } from '@material-ui/core';


const LoginWithEmailPass = () => {
  const navigate = useNavigate();
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  let currentUser = useStore((state) => state.currentUser);
  const [error, setError] = useState("");
  useEffect(() => {
    if(currentUser?.id){
      navigate("/schools")
    }

  }, [currentUser])
  
  const email = useField({ validate: isEmailValid });
  const password = useField({
    validate: isRequired,
  });
  const [showPassword, setShowPassword] = useState(false);

  const formErrCleanupValidation = () => {
    email.setShowError(true);
    password.setShowError(true);
    if (!email.isValid || !password.isValid) return false;
    return true;
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const { login } = useAuthHelper();

  const onFormSubmit = async () => {
    if (formErrCleanupValidation()) {
      let res = await login({
        userName: email.value.toLowerCase(),
        password: password.value,
        loginForced: true,
        // "browserId": "Chrome",
        // "deviceId": "Mac"
      });
      if (res.data?.success) {
        if(["SUPER_ADMIN", "CHANNEL"].includes(res.data?.data?.userRole)){
          setCurrentUser(res.data?.data);
          navigate("/schools")
        } else {
          setError("You are not authorized to access this website")
        }
      }
    }
  };

  function handleEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onFormSubmit()
    }
  }


  return (
    <FlexContainer direction="column">
      {/* <ArrowBackIcon sx={backIconStyles()} onClick={() => navigate(-1)} /> */}
      <Input
        {...email.getInputProps()}
        errorText={email.showError && email.meta.error}
        label="E-mail Address"
        style={{ marginBottom: "32px" }}
        InputLabelProps={{
          style: {
            // fontSize: "12px", width: "87px",
            // height: "13px",
            //   flexGrow: "0",
            //   fontWeight: "500",
            //   fonStretch: "normal",
            //   fontStyle: "normal",
            //   lineHeight: "normal",
            //   letterSpacing: "0.24px",
            //   textAlign: "left",

            // marginLeft: '5px',
            flexGrow: "0",
            fontWeight: "500",
            fonStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "0.24px",
            textAlign: "left",
          }
        }}
      />
      {/* <Spacer height={50} /> */}
      <Input
        {...password.getInputProps()}
        errorText={password.showError && password.meta.error}
        label="Password"
        // defaultValue="@aimlearn.co"
        obscureText={!showPassword}
        style={{ marginBottom: "20px" }}
        InputLabelProps={{
          style: {
            // fontSize: "12px", width: "87px",


            // height: "13px",
            //   flexGrow: "0",
            //   fontWeight: "500",
            //   fonStretch: "normal",
            //   fontStyle: "normal",
            //   lineHeight: "normal",
            //   letterSpacing: "0.24px",
            //   textAlign: "left",

            // marginLeft: '5px',
            flexGrow: "0",
            fontWeight: "500",
            fonStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "0.24px",
            textAlign: "left",
          }
        }}
        onKeyPress={handleEnter}

        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {/* <Spacer height={1} /> */}


      {/* <Spacer /> */}
      <Button onClick={onFormSubmit} variant="contained" component="span" style={{ borderRadius: '50px', fontSize: '14px', backgroundColor: '#82D2DA', boxShadow: 5, fontWeight: 550, margin: 'auto', outline: 'none' }}  >Login</Button>
      {error && <Alert sx={{mt:2}} severity="error">{error}</Alert>}
      {/* <ButtonCustom onClick={onFormSubmit}>
        Login
      </ButtonCustom> */}
      {/* <Spacer /> */}
      <Typography
        sx={{
          cursor: "pointer",
          opacity: 0.9,
          fontFamily: "Graphik",
          fontSize: "14px",
          fontWeight: 550,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "0.18px",
          textAlign: "center",
          color: "#005b64",
          marginTop: "24px"
        }}
        onClick={() => {
          navigate("/forgotpass");
        }}
        color="#005B64"
      >
        Forgot Password?
      </Typography>
    </FlexContainer>
  );
};

export default LoginWithEmailPass;
