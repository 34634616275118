import { User } from "../models/user";
import { AppConstants } from "../constants/app-constants";
import { environment } from "../environments/environment";
import { checkAuthCookie } from "../utils/helpers";

let authStore = (set) => ({
  currentUserSubject: User,
  currentUser: User,
  UserObj: User,
  reportData:{},

  setCurrentUser: (userData: User) => {
    set({ currentUser: userData });
    const userDetails = JSON.stringify(userData);
    localStorage.setItem("user",userDetails)
    // document.cookie = (AppConstants.SESSION_STORAGE_ITEMS.USER_INFO + "=" + userDetails + ";domain=" + environment.cookieDomain + "; path=/").trim();
    // checkAuthCookie();
  },

  clearCurrentUser: (redirect=true) => {
    set({ currentUser: null });
    localStorage.clear()
    // document.cookie = (AppConstants.SESSION_STORAGE_ITEMS.USER_INFO + "=logged-out;domain=" + environment.cookieDomain + "; path=/").trim();
    if (redirect) window.location.replace("/");
  },

  setReportData: (reportData) => {
    set({ reportData: reportData });
  }
});

export default authStore;
