import {
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../components/elements/Input";
import Spacer from "../../components/elements/Spacer";
import useAuthHelper from "../../hooks/useAuthHelper";
import { useField } from "../../hooks/useField";
import { isPasswordValid, isRequired } from "../../utils/validators";
import InfoIcon from "@mui/icons-material/Info";
import FlexContainer from "../../components/elements/FlexContainer";
import ButtonCustom from "../../components/elements/ButtonCustom";
import { useStore } from "../../stores";
import { checkAuthCookie } from "../../utils/helpers";

const ResetPass = (props) => {
  const { resetPassword } = useAuthHelper();
  const [searchParams] = useSearchParams();
  const clearCurrentUser = useStore((state) => state.clearCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.cleanUser) clearCurrentUser(false);
    checkAuthCookie();
  }, [props, clearCurrentUser]);

  const password = useField({
    validate: isPasswordValid,
  });
  const confirmPassword = useField({ validate: isRequired });
  const [passwordNoMatchError, setPasswordNoMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const formErrCleanupValidation = () => {
    password.setShowError(true);
    setPasswordNoMatchError("");

    if (!password.isValid) return false;
    if (password.value !== confirmPassword.value) {
      setPasswordNoMatchError("Passwords do not match");
      return false;
    }
    return true;
  };
  const onResetPassword = async () => {
    if (formErrCleanupValidation()) {
      let res = await resetPassword(password.value, searchParams.get("token"));
      if (res.data?.success) {
        // setResetSuccess(true);
        navigate("/login/phone");
      }
    }
  };
  return (
    <FlexContainer>
      <Card sx={{ py: 3, px: 5, width: "350px", mt: 5 }}>
        <Typography textAlign={"center"}>
          Reset Your Password
        </Typography>
        <Spacer />
        <Input
          {...password.getInputProps()}
          errorText={password.showError && password.meta.error}
          label="New Password"
          obscureText={!showPassword}
        />
        <Spacer width="1vw" />
        <Input
          {...confirmPassword.getInputProps()}
          errorText={password.showError && confirmPassword.meta.error}
          label="Confirm Password"
          obscureText={!showPassword}
        />
        <Typography
          pl="1rem"
          color={"error"}
          fontSize=".8rem"
          sx={{ alignSelf: "flex-start" }}
        >
          {passwordNoMatchError ? <InfoIcon fontSize="small" /> : null}
          {passwordNoMatchError}
        </Typography>
        <Spacer height={2} />

        <FormControlLabel
          sx={{ alignSelf: "flex-start" }}
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: " #6C367E",
                },
              }}
              onChange={(e) => {
                setShowPassword(e.target.checked);
              }}
            />
          }
          label="Show Password"
        />
        <Spacer />
        <ButtonCustom onClick={onResetPassword}>Reset</ButtonCustom>
      </Card>
    </FlexContainer>
  );
};

export default ResetPass;
