import React, { useEffect } from "react";
import AuthTabs from "./AuthTabs";
import AuthLayout from "./AuthLayout";
import { checkAuthCookie } from "../../utils/helpers";

const AuthScreen = () => {

  return (
    <AuthLayout>
      <AuthTabs></AuthTabs>
    </AuthLayout>
  );
};

export default AuthScreen;
