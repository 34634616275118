
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(createTheme({


  typography: {
    fontFamily: 'Graphik'
  },
  palette: {
    primary: {
      main: "#82D2DA",
      dark: "#7DCAD1",
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.38)",
      light: "rgba(0, 0, 0, 0.6)",
      black: "rgba(0, 0, 0, 0.87)",
    },

    tertiary: {
      main: "rgb(255,255,255)"
    },

    violet: {
      main: "#6C367E",
      light: "#895e97",
      dark: "#4b2558",
    },
    info: {
      main: "#6C367E",
      light: "#895e97",
      dark: "#4b2558",
    },
    background: { default: "#FBF5F2" },
    error: {
      main: "#B00020",
    },
  },
}));


theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

export default theme;
