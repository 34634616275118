import React, { useEffect } from "react";
import MaterialTable from "material-table";

import { Checkbox, Divider, FormControlLabel, FormGroup, IconButton, ImageList, ImageListItem, Link,Modal,Typography } from "@mui/material";

import { Button, Grid } from "@mui/material";
import GlobalLayout from "../../components/layout/GlobalLayout";
import { useState } from "react";
import { useQuery } from "react-query";
import useUsersHelper from "../../hooks/useAdminHelper";
import { CustomSnackbar } from "../../components/elements/CustomSnackbar";
import { studentInfoObj } from "../../hooks/useFeatureHelper";
import useSessionHelper from "../../hooks/useSessionHelper";
import { CsvBuilder } from "filefy";
import { useStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import CloseIcon from '@mui/icons-material/Close';

import { set } from "date-fns";
import { Box } from "@mui/system";
import { render } from "react-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius:'20px',
  boxShadow: 24,
  p: 4,
};


const Reports = () => {

  const navigate = useNavigate();

  const {getReports, getSessionImages, saveMedal} = useSessionHelper();
  const setReportData = useStore((state)=>state.setReportData);
  const currentUser = useStore((state) => state.currentUser)
  const [modalOpen, setModalOpen] = useState(false);
  const [images,setImages] = useState([]);
  
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [tableArr, setTableArr] = React.useState([]);
  const [tableTotalCount, setTableTotalCount] = useState(1);
  const [schoolName,setSchoolName]=useState("");
  const [modalData, setModalData] = useState({});
  const { getUsersGrade, getUsers } = useUsersHelper();
  const query = new URLSearchParams(window.location.search);
  const schoolId = query.get("schoolId");
  const subject = query.get("subject");
  const temp = query.get("grade");
    const grade = temp.charAt(0) == "0" ? temp.substring(1,temp.length) : temp;
  const { data: ReportData, isLoading: userLoader, refetch } = useQuery(
    [`ReportData`, grade, schoolId, subject],
    () => getReports({grade, schoolId, subject, meta:false}),
    { enabled: !!grade && !!schoolId && !!subject, retry: false }
  );

  const {data: SchoolData} = useQuery(['SchoolData',schoolId],()=>getUsersGrade(studentInfoObj(schoolId,null,null,grade)),{enabled:!!schoolId})

   useEffect(() => {
    
    if (ReportData && SchoolData) {
      let temparr = []
      let students = {}
      SchoolData?.data?.data?.students?.data?.forEach((student) => {
        if(student?.subjects?.findIndex((sub) => sub == subject)!=-1){
          students[student['fullName']] = {'id':student['id'],'name':student['fullName'],'a':0}
        }
      })
      ReportData?.data?.data?.batchReports?.forEach((batchReport) => {
        console.log( Object.values(students))
        Object.values(students).forEach((student) => {
          student['a'] = 0; 
        })
        batchReport?.studentReports.forEach((studentReport) => {
          if(students[studentReport['name']]){
            students[studentReport['name']]['a'] = 1 
          }
          temparr.push({
            'testName': batchReport['testName'],
            'totalQuestions': batchReport['totalQuestions'],
            'packageId':batchReport['packageId'],
            'userId':studentReport['userId'],
            'studentName': studentReport['name'],
            'correct': studentReport['correct'],
            'incorrect': studentReport['incorrect'],
            'questions':studentReport['questions'],
            "score": studentReport['correct'],
            'notAttempted': studentReport['notAttempted'],
            "sessionId": studentReport['sessionId'],
            'rank':studentReport['rank'],
            "absent":false,

            "medal" : studentReport['medal'],
            'nationalRank':studentReport['nationalRank'],

          });
        })
        Object.values(students).forEach((student) => {
          if(student['a']==0){
            temparr.push({
              'testName': batchReport['testName'],
              'totalQuestions': batchReport['totalQuestions'],
              'packageId':batchReport['packageId'],
              'userId':student['id'],
              'studentName': student['name'],
              'correct': 0,
              'incorrect': 0,
              'questions':0,
              "score": 0,
              'notAttempted': 0,
              "sessionId": 0,
              "absent":true
            });
          }
        })
      });
      setTableArr(temparr)
      setTableTotalCount(temparr.length)
      console.log("Reportss",ReportData?.data?.data?.batchReports[0].testName);
      console.log("GOLDS",ReportData?.data?.data?.subjectReport[0]?.gold);
    }
    
  }, [ReportData,SchoolData]);

  const getImages = async (sessionId) => {
    const resp = await getSessionImages(sessionId)
    if(resp?.data?.success){
      setModalOpen(true);
      setImages(resp?.data?.data)
      setModalData(resp?.data?.data[sessionId])
    }
  }

  const closeModal = () => {
    setModalOpen(false);
    setImages([])
  }

  const reportRedirect = (report) => {
    setReportData(report);
    navigate('/report')
  }

  const onMedalChange=(medal, userId, packageId)=>{
    saveMedal({packageId, schoolId, userId, ...medal}).then(()=>refetch());
  }
const handleMedalChange=(rowData,medal)=>{
  if(medal){
    if(rowData['medal'] == "GOLD"){
      rowData['medal'] = "NA";
      onMedalChange({gold:false},rowData.userId,rowData.packageId)
    } else{
      rowData['medal'] = "GOLD";
      onMedalChange({gold:true},rowData.userId,rowData.packageId)
    }
  } else {
    if(rowData['medal'] == "SILVER"){
      rowData['medal'] = "NA";
      onMedalChange({silver:false},rowData.userId,rowData.packageId)
    } else{
      rowData['medal'] = "SILVER";
      onMedalChange({silver:true},rowData.userId,rowData.packageId)
    }
  }
}

 return (
    <GlobalLayout loader={userLoader}>
      <Grid container m={2} pr={3} style={{ marginTop: 100 }}>
      <Grid
          container
          direction="row"
          alignItems="center"
        ><Grid item xs={6} lg={6}>
            <Typography sx={{fontFamily:"GraphikMedium.oft",fontSize: '12px',fontWeight: 600,marginLeft:"5px"}}>{SchoolData?.data?.data?.students?.data[0]?.schoolName}</Typography>
            </Grid>
            <Grid container
               direction="row"
               alignItems="center"
               justifyContent="left"
            ><Grid item xs={6} lg={6}>
              <Typography sx={{fontFamily:'GraphikMedium.oft',fontWeight:'600',marginLeft:'5px'}}>
               {ReportData?.data?.data?.batchReports[0].testName}
              </Typography>
              
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
            <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
         
        </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <MaterialTable
            columns={[
              { title: 'Student Name', field: 'studentName' },
              { title: 'Correct', field: 'correct' },
              { title: 'Incorrect', field: 'incorrect'},
              { title: 'Not Attempted', field: 'notAttempted' },
              { title: 'School Rank', field: 'rank' },

              { title: 'National Rank',field:'nationalRank'},
              { title: 'Medals', field: 'Gold',
                render:rowData =>{
                  return(
                  <FormGroup sx={{display:'flex', flexDirection:'row'}}>
                    <FormControlLabel control={<Checkbox checked={rowData?.medal==='GOLD'} disabled={currentUser.userRole!=="SUPER_ADMIN"} onChange={() => handleMedalChange(rowData, true)}/>} label="Gold" />
                    <FormControlLabel control={<Checkbox checked={rowData?.medal==='SILVER'} disabled={currentUser.userRole!=="SUPER_ADMIN"} onChange={() => handleMedalChange(rowData, false) }/>} label="Silver" />
                  </FormGroup>

                  )
                }
              },
              { 
                title: 'Detailed Report', field: 'status', 
                render: rowData => {
                  return (
                    <>
                    { !rowData['absent'] && <Grid container>
                       <Grid item>
                        <Button sx={{marginLeft:'-15px'}} variant="contained" onClick={()=>reportRedirect(rowData)}>
                          <ArrowCircleRightIcon sx={{'fill':'whitesmoke'}}/>
                        </Button>
                        </Grid >
                        <Grid item>
                        <Button sx={{marginLeft:'5px'}} variant="contained" onClick={()=>getImages(rowData['sessionId'])}>
                          <VideoCameraFrontIcon sx={{'fill':'whitesmoke'}}/>
                        </Button>
                        </Grid>
                        
                    </Grid>}
                    </>

                  )
                }
              }
            ]}
            totalCount={tableTotalCount}
            data={tableArr}
            title="Reports"
            options={{
              pageSize: 100,
              exportButton: true,
              headerStyle: {
                backgroundColor: '#82D2DA'
              },
              exportCsv: (columns, data) => {
                const columnTitles = columns.map(
                  (columnDef) => columnDef.title
                );

                const csvData = data.map((rowData) =>
                  columns.map((columnDef) => rowData[columnDef.field])
                );

                const builder = new CsvBuilder(`reports_${schoolName}_Class ${grade}_${subject}.csv`)
                  .setColumns(columnTitles)
                  .addRows(csvData)
                  .exportFile();

                return builder;
              },
            }}
          />
        </Grid>
      </Grid>
      <Modal
          open={modalOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{display:'flex',justifyContent:'space-between'}}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Images captured during session on {modalData?.started?.split(".")[0].split("T")[0]}
              </Typography>
              <IconButton onClick={closeModal}>
                  <CloseIcon/>
                </IconButton>
            </Box>
            <Typography>
               from {modalData?.started?.split(".")[0].split("T")[1]} to {modalData?.completed?.split(".")[0].split("T")[1]}
            </Typography>
            <Divider orientation="horizontal" flexItem sx={{m:"10px"}}/>
            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {modalData?.images?.map((item) => (
              <ImageListItem key={item.split("/").slice(-1)[0]}>
                <img
                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.split("/").slice(-1)[0]}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          </Box>
        </Modal>
      {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
    </GlobalLayout>
  );
};

export default Reports;
